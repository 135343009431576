import { MatDialogRef, MatHorizontalStepper, MatStepper, MAT_DIALOG_DATA } from '@angular/material';
import { Inject, Component, ViewChild } from '@angular/core';
import { contratos } from '@shared/contrato.entity';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpService } from 'src/app/services/http/http.service';
import {ValidatorService} from 'angular-iban';
import jsPDF from 'jspdf'; // will automatically load the node version
import { NgxSpinnerService } from 'ngx-spinner';
import { Alert } from 'selenium-webdriver';

@Component({
    selector: 'INV_creacion-edicion-contrato',
    templateUrl: 'INV_creacion-edicion-contrato.html',
    styleUrls: ['./INV_creacion-edicion-contrato.css']
  })
  export class      INV_CreacionEdicionContrato {

    @ViewChild("stepper", { static: false })
    stepper: MatHorizontalStepper;
    CUPSFormGroupEditable = true;
    codigoCupsCorrecto = false;
    //contrato = new contratos(); 
    public ibanReactive: FormControl;
    especificarFacturacion=false;
    primeraResidencia=true;
    ttt="";
    tipoObtencionDatos = "W";
    isLinear = false;
    CUPSFormGroup: FormGroup;
    datosTitularFormGroup: FormGroup;
    datosSuministroFormGroup: FormGroup;
    datosTarifaFormGroup: FormGroup;
    datosFacturacionFormGroup: FormGroup;
    datosInternosFormGroup: FormGroup;
    guardarDeshabilitado=true;
    public gruposTarifa:Array<interficieLlaveValor>;
    public tarifas:Array<interficieLlaveValor>;
    public idiomas:Array<interficieLlaveValor>;
    fechaFormalizacionContrato;

    

    constructor(
      public dialogRef: MatDialogRef<INV_CreacionEdicionContrato>,
      @Inject(MAT_DIALOG_DATA) public data,
      private _formBuilder: FormBuilder,
      public http:HttpService,
      private spinner: NgxSpinnerService) {

        this.spinner.show();
        this.http.obtenerProfile();
        this.evaluaSiEsPermitidoGuardar();

        this.obtenerIdiomas();
        this.obtenerTarifas();
        this.obtenerGruposTarifa();

        this.fechaFormalizacionContrato =  new FormControl(new Date());
        
        if (!this.data.edicion)
        {
            this.especificarFacturacion = true;            
            //this.data.contrato.CUPS = "ES0031101736812001HV0F";
            this.data.contrato.texto_tipo_impresion = "E";
            this.data.contrato.tipo_solicitud = "n";
            this.data.contrato.idioma_preferido = "ES";
            this.chkPrimeraResidenciaChange(true);

            this.data.contrato.potencia_suministro = "";
            this.data.contrato.potencia_suministro_p2 = "";
            this.data.contrato.potencia_suministro_p3 = "";
            this.data.contrato.potencia_suministro_p4 = "";
            this.data.contrato.potencia_suministro_p5 = "";
            this.data.contrato.potencia_suministro_p6 = "";

            this.data.contrato.fecha_formalizacion_contrato =new Date();
            this.fechaFormalizacionContrato.value = new FormControl(new Date());

            this.http.obtenerNumeracionCorrelativaContrato().then(
                (numeracionContrato) => {
                    if (numeracionContrato[0] != null)
                        this.data.contrato.numeracion = numeracionContrato[0]["NUMERACION"]; //ESTIC AQWUI PROVAR AIXO
                    
                }
            );
        }
        else 
        {
            //alert(this.data.contrato.especificar_direccion_facturacion);
            if (this.data.contrato.especificar_direccion_facturacion == "S")
              this.especificarFacturacion = false;
            else 
              this.especificarFacturacion = true;

            if (this.data.contrato.primera_residencia == 1)
              this.primeraResidencia = true;
            else 
              this.primeraResidencia = false;

            if(this.data.exportarPDF)
            {
                this.descargarPDF();
                this.dialogRef.close();
            }
            else
            {

                this.CUPSFormGroupEditable = false;

                this.fechaFormalizacionContrato.value = this.data.contrato.fecha_formalizacion_contrato;

                setTimeout(() => (this.stepper.selectedIndex = 1), 0);           
                            
               
            }
        }
         this.CUPSFormGroup = this._formBuilder.group({          
          CUPSCtrl: ['', Validators.required],
          tipoObtencionDatosCtrl: ['',Validators.required],
          wizardCtrl: ['',]
        });

        this.ibanReactive = new FormControl(
          null,
            [
              
              ValidatorService.validateIban
            ]
        );

        this.datosTitularFormGroup = this._formBuilder.group({
          direccionCtrl: ['',Validators.required],
          codigoPostalCtrl: ['',Validators.required],
          poblacionCtrl: ['',Validators.required],
          provinciaCtrl: ['',Validators.required],
        //  provinciaCtrl: ['',Validators.required],
          nombreTitularCtrl: ['',],
          apellido1TitularCtrl: ['',],
          apellido2TitularCtrl: ['',],
          telefonoTitularCtrl: ['',Validators.required],
          DNICtrl: ['',Validators.required],          
          IBANCtrl: this.ibanReactive,
          correoTitularCtrl: ['',Validators.pattern('[a-zA-Z0-9.-_-]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}')],//TODO: ERROR GUIÓN
          aclaradorCtrl: [''],
          numeroCtrl: ['',],
          nombreEmpresaCtrl: ['']
        });

        this.datosSuministroFormGroup = this._formBuilder.group({
            CUPSSuministroCtrl: ['',],
            PotenciaSuministroCtrl: ['',Validators.required],
            PotenciaSuministroP2Ctrl: ['',],
            PotenciaSuministroP3Ctrl: ['',Validators.required],
            PotenciaSuministroP4Ctrl: ['',],
            PotenciaSuministroP5Ctrl: ['',],
            PotenciaSuministroP6Ctrl: ['',],
        });

        this.datosFacturacionFormGroup = this._formBuilder.group({
            direccionFactCtrl: ['',],
            aclaradorFactCtrl: ['',],
            numeroFactCtrl: ['',],
            codigoPostalFactCtrl: ['',],
            poblacionFactCtrl: ['',],
            provinciaFactCtrl: ['',],
            especificarFactCtrl: ['',]
        });
  
        this.datosTarifaFormGroup = this._formBuilder.group({
          GrupoTarifaCtrl: ['',Validators.required],
          TarifaCtrl: ['',Validators.required],
          //Potencia1Ctrl: ['',Validators.pattern('\\-?\\d*\\.?\\d{1,15}')],
          //Potencia2Ctrl: ['',Validators.pattern('\\-?\\d*\\.?\\d{1,15}')],
          //Potencia3Ctrl: ['',Validators.pattern('\\-?\\d*\\.?\\d{1,15}')],
          //Potencia4Ctrl: ['',Validators.pattern('\\-?\\d*\\.?\\d{1,15}')],
          //Potencia5Ctrl: ['',Validators.pattern('\\-?\\d*\\.?\\d{1,15}')],
          //Potencia6Ctrl: ['',Validators.pattern('\\-?\\d*\\.?\\d{1,15}')],

          Potencia1Ctrl: ['',Validators.required],
          Potencia2Ctrl: ['',],
          Potencia3Ctrl: ['',Validators.required],
          Potencia4Ctrl: ['',],
          Potencia5Ctrl: ['',],
          Potencia6Ctrl: ['',],
          Energia1Ctrl: ['',Validators.required],//Validators.pattern('\\-?\\d*\\.?\\d{1,15}')
          Energia2Ctrl: ['',Validators.required],
          Energia3Ctrl: ['',Validators.required],
          Energia4Ctrl: ['',],
          Energia5Ctrl: ['',],
          Energia6Ctrl: ['',]
          
        });

        this.datosInternosFormGroup = this._formBuilder.group({
          agenteCtrl: ['',],
          codigoAgenteCtrl: ['',],
          numeroContratoCtrl: ['',],
          IdiomaCtrl: ['',Validators.required],
          codigoPromocionalCtrl: ['',],
          TipoImpresionCtrl: ['',Validators.required],
          TipoSolicitudCtrl: ['',Validators.required],
          cnaeCtrl: ['',],
          cambiosCtrl: ['',],
          EnvioContratoCtrl: ['',],
          autoconsumoCtrl: ['',],
          FechaFormalizacionContratoCtrl: ['',]
        });


        this.chkFacturacionChange(this.especificarFacturacion);
        this.spinner.hide();
 
        if (!this.data.exportarPDF)
        {
            this.datosTitularFormGroup.markAllAsTouched();
            this.CUPSFormGroup.markAllAsTouched();
            this.datosInternosFormGroup.markAllAsTouched();
            this.datosSuministroFormGroup.markAllAsTouched();
            this.datosFacturacionFormGroup.markAllAsTouched();

            this.changeTarifa(this.data.contrato.tarifa);
        }

    }

    eliminarDiacriticosEs(texto) {
        return texto
               .normalize('NFD')
               .replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1")
               .normalize();
    }

    changeCorreoTitular()
    {
        this.data.contrato.correo_titular = this.data.contrato.correo_titular.trim();
        //correoTitularCtrl
        this.datosTitularFormGroup.controls["correoTitularCtrl"].value(this.data.contrato.correo_titular);
    }

    changeEnvioContrato(valor)
    {
         
        this.datosInternosFormGroup.controls["envio_contrato"].value(valor);
    }

    evaluaChkPrimeraResidenciaSegunCodigoCNAE()
    {
        if (this.data.contrato.codigo_cnae.includes("9820"))
        {
            this.chkPrimeraResidenciaChange(true);
        }            
        else 
        {
            this.chkPrimeraResidenciaChange(false);
        }            
    }

    obtenerCampoNemon(_campo)
    {

        
        this.http.getNemonByCUPS(this.data.contrato.CUPS).then(
            (datosNemon) => {        

                if (_campo == "codigo_cnae")
                {
                    
                    if ((datosNemon["suministros"][0]["codigo_cnae"] != null) && (datosNemon["suministros"][0]["cnae_code"] != ""))
                    {
                        this.data.contrato["codigo_cnae"] = datosNemon["suministros"][0]["cnae_code"];
                        this.evaluaChkPrimeraResidenciaSegunCodigoCNAE();
                    }                        
                }                    
                else if (_campo == "direccion")
                {
                    if ((datosNemon["suministros"][0]["Direccion_Suministro"] != null) && (datosNemon["suministros"][0]["Direccion_Suministro"] != ""))
                        this.data.contrato["direccion"] = datosNemon["suministros"][0]["Direccion_Suministro"];
                }    
                else if (_campo == "provincia")                
                {
                    if ((datosNemon["suministros"][0]["Provincia_Suministro"] != null) && (datosNemon["suministros"][0]["Provincia_Suministro"] != ""))
                        this.data.contrato["provincia"] = datosNemon["suministros"][0]["Provincia_Suministro"];
                }
                else if (_campo == "codigo_postal")
                {
                    if ((datosNemon["suministros"][0]["Cod_Postal_Suministro"] != null) && (datosNemon["suministros"][0]["Cod_Postal_Suministro"] != ""))
                        this.data.contrato.codigo_postal = datosNemon["suministros"][0]["Cod_Postal_Suministro"];
                }
                else if (_campo == "ciudad")
                {
                    if ((datosNemon["suministros"][0]["Localidad_Suministro"] != null) && (datosNemon["suministros"][0]["Localidad_Suministro"] != ""))
                        this.data.contrato.ciudad = datosNemon["suministros"][0]["Localidad_Suministro"];
                }
                else if (_campo == "tarifa")
                {
                    if ((datosNemon["suministros"][0]["Tarifa"] != null) && (datosNemon["suministros"][0]["Tarifa"] != ""))
                    {
                        this.tarifas.forEach(element => {
                            if (element.label == datosNemon["suministros"][0]["Tarifa"])
                                this.data.contrato.tarifa = element.value;
                            });
                    }
                }
                else if (_campo == "potencia_P1")
                {
                    if ((datosNemon["suministros"][0]["Pot_Cont_P1"] != null) && (datosNemon["suministros"][0]["Pot_Cont_P1"] != ""))
                        this.data.contrato.potencia_P1 = datosNemon["suministros"][0]["Pot_Cont_P1"];
                }
                else if (_campo == "potencia_P2")
                {
                    if ((datosNemon["suministros"][0]["Pot_Cont_P2"] != null) && (datosNemon["suministros"][0]["Pot_Cont_P2"] != ""))
                        this.data.contrato.potencia_P2 = datosNemon["suministros"][0]["Pot_Cont_P2"];
                }
                else if (_campo == "potencia_P3")
                {
                    if ((datosNemon["suministros"][0]["Pot_Cont_P3"] != null) && (datosNemon["suministros"][0]["Pot_Cont_P3"] != ""))
                        this.data.contrato.potencia_P3 = datosNemon["suministros"][0]["Pot_Cont_P3"];
                }
                else if (_campo == "potencia_P4")
                {
                    if ((datosNemon["suministros"][0]["Pot_Cont_P4"] != null) && (datosNemon["suministros"][0]["Pot_Cont_P4"] != ""))
                        this.data.contrato.potencia_P4 = datosNemon["suministros"][0]["Pot_Cont_P4"];
                }
                else if (_campo == "potencia_P5")
                {
                    if ((datosNemon["suministros"][0]["Pot_Cont_P5"] != null) && (datosNemon["suministros"][0]["Pot_Cont_P5"] != ""))
                        this.data.contrato.potencia_P5 = datosNemon["suministros"][0]["Pot_Cont_P5"];
                }
                else if (_campo == "potencia_P6")
                {
                    if ((datosNemon["suministros"][0]["Pot_Cont_P6"] != null) && (datosNemon["suministros"][0]["Pot_Cont_P6"] != ""))
                        this.data.contrato.potencia_P6 = datosNemon["suministros"][0]["Pot_Cont_P6"];
                }
                else if (_campo == "energia_P1")
                {
                    if ((datosNemon["suministros"][0]["kWhAnual_pctge_p1"] != null) && (datosNemon["suministros"][0]["kWhAnual_pctge_p1"] != ""))
                        this.data.contrato.energia_P1 = datosNemon["suministros"][0]["kWhAnual_pctge_p1"];
                }
                else if (_campo == "energia_P2")
                {
                    if ((datosNemon["suministros"][0]["kWhAnual_pctge_p2"] != null) && (datosNemon["suministros"][0]["kWhAnual_pctge_p2"] != ""))
                        this.data.contrato.energia_P2 = datosNemon["suministros"][0]["kWhAnual_pctge_p2"];
                }
                else if (_campo == "energia_P3")
                {
                    if ((datosNemon["suministros"][0]["kWhAnual_pctge_p3"] != null) && (datosNemon["suministros"][0]["kWhAnual_pctge_p3"] != ""))
                        this.data.contrato.energia_P3 = datosNemon["suministros"][0]["kWhAnual_pctge_p3"];
                }
                else if (_campo == "energia_P4")
                {
                    if ((datosNemon["suministros"][0]["kWhAnual_pctge_p4"] != null) && (datosNemon["suministros"][0]["kWhAnual_pctge_p4"] != ""))
                        this.data.contrato.energia_P4 = datosNemon["suministros"][0]["kWhAnual_pctge_p4"];
                }
                else if (_campo == "energia_P5")
                {
                    if ((datosNemon["suministros"][0]["kWhAnual_pctge_p5"] != null) && (datosNemon["suministros"][0]["kWhAnual_pctge_p5"] != ""))
                        this.data.contrato.energia_P5 = datosNemon["suministros"][0]["kWhAnual_pctge_p5"];
                }
                else if (_campo == "energia_P6")
                {
                    if ((datosNemon["suministros"][0]["kWhAnual_pctge_p6"] != null) && (datosNemon["suministros"][0]["kWhAnual_pctge_p6"] != ""))
                        this.data.contrato.energia_P6 = datosNemon["suministros"][0]["kWhAnual_pctge_p6"];
                }
                
                    

        });
        
    }

    chkPrimeraResidenciaChange(event)
    {
        if (event)
        {
            this.data.contrato.primera_residencia = 1;
            this.primeraResidencia = true;
        }          
        else 
        {
            this.data.contrato.primera_residencia = 0;
            this.primeraResidencia = false;
        }          
    }

    chkFacturacionChange(event)
    {
        
        this.especificarFacturacion = event;     
        
        if (this.especificarFacturacion)
        {
            this.datosFacturacionFormGroup.controls["direccionFactCtrl"].disable();
            this.datosFacturacionFormGroup.controls["aclaradorFactCtrl"].disable();
            this.datosFacturacionFormGroup.controls["numeroFactCtrl"].disable();
            this.datosFacturacionFormGroup.controls["codigoPostalFactCtrl"].disable();
            this.datosFacturacionFormGroup.controls["poblacionFactCtrl"].disable();
            this.data.contrato.especificar_direccion_facturacion = "N";
        }
        else 
        {
            this.datosFacturacionFormGroup.controls["direccionFactCtrl"].enable();
            this.datosFacturacionFormGroup.controls["aclaradorFactCtrl"].enable();
            this.datosFacturacionFormGroup.controls["numeroFactCtrl"].enable();
            this.datosFacturacionFormGroup.controls["codigoPostalFactCtrl"].enable();
            this.datosFacturacionFormGroup.controls["poblacionFactCtrl"].enable();
            this.data.contrato.especificar_direccion_facturacion = "S";
        }
        
        //this.firstFormGroup.controls["viviendaHabitualCtrl"].setValue(event); 
    }
  

    ejecutaWizard()    
    {
        this.spinner.show();
        try {
            var z = this.ttt.split(/\r?\n/);
            z.forEach(element => {
    
                //alert(element.toString().toUpperCase().replace(/[&\/\\#,+()$~%.'"*?<>{}]/g,''));
            
                var elmFormatado = element.toString().toUpperCase().replace(/[&\/\\#()$~%'"*?<>{}]/g,'');
              //alert(element.toString().toUpperCase());
              if (elmFormatado.includes("POTENCIA:"))
              {
                  
                try {
                  
                    //alert("LOG " + element.toString().toUpperCase().split('POTENCIA:')[1].trimRight().trimLeft().replace(",","."));
     
                    if (elmFormatado.split('POTENCIA:')[1] != null)
                    {
                        var arrPotencias = elmFormatado.split('POTENCIA:')[1].split(';');
                        
                        if (arrPotencias[0] != null)
                        {
                            this.data.contrato.potencia_suministro = arrPotencias[0].trimRight().trimLeft().replace(",",".");
                            this.data.contrato.potencia_suministro = this.data.contrato.potencia_suministro.toString().replace(/[^\x00-\x7F]/g, "");                         
                            this.datosSuministroFormGroup.controls["PotenciaSuministroCtrl"].setValue(this.data.contrato.potencia_suministro);
                        }
                        if (arrPotencias[1] != null)
                        {
                            
                            this.data.contrato.potencia_suministro_p2 = arrPotencias[1].trimRight().trimLeft().replace(",",".");
                            this.data.contrato.potencia_suministro_p2 = this.data.contrato.potencia_suministro_p2.toString().replace(/[^\x00-\x7F]/g, "");                         
                            this.datosSuministroFormGroup.controls["PotenciaSuministroP2Ctrl"].setValue(this.data.contrato.potencia_suministro_p2);
                        }
                        if (arrPotencias[2] != null)
                        {
                            this.data.contrato.potencia_suministro_p3 = arrPotencias[2].trimRight().trimLeft().replace(",",".");
                            this.data.contrato.potencia_suministro_p3 = this.data.contrato.potencia_suministro_p3.toString().replace(/[^\x00-\x7F]/g, "");                         
                            this.datosSuministroFormGroup.controls["PotenciaSuministroP3Ctrl"].setValue(this.data.contrato.potencia_suministro_p3);
                        }
                        if (arrPotencias[3] != null)
                        {
                            this.data.contrato.potencia_suministro_p4 = arrPotencias[3].trimRight().trimLeft().replace(",",".");
                            this.data.contrato.potencia_suministro_p4 = this.data.contrato.potencia_suministro_p4.toString().replace(/[^\x00-\x7F]/g, "");                         
                            this.datosSuministroFormGroup.controls["PotenciaSuministroP4Ctrl"].setValue(this.data.contrato.potencia_suministro_p4);
                        }
                        if (arrPotencias[4] != null)
                        {
                            this.data.contrato.potencia_suministro_p5 = arrPotencias[4].trimRight().trimLeft().replace(",",".");
                            this.data.contrato.potencia_suministro_p5 = this.data.contrato.potencia_suministro_p5.toString().replace(/[^\x00-\x7F]/g, "");                         
                            this.datosSuministroFormGroup.controls["PotenciaSuministroP5Ctrl"].setValue(this.data.contrato.potencia_suministro_p5);
                        }
                        if (arrPotencias[5] != null)
                        {
                            this.data.contrato.potencia_suministro_p6 = arrPotencias[5].trimRight().trimLeft().replace(",",".");
                            this.data.contrato.potencia_suministro_p6 = this.data.contrato.potencia_suministro_p6.toString().replace(/[^\x00-\x7F]/g, "");                         
                            this.datosSuministroFormGroup.controls["PotenciaSuministroP6Ctrl"].setValue(this.data.contrato.potencia_suministro_p6);
                        }
                        
                        
                    }                    
                        
                  } catch (error) {
                    //alert("ERR");
                  }           
    
              }
              else if (elmFormatado.includes('NOMBRE:'))
              {
                
                  try {
                    
                    if (elmFormatado.split('NOMBRE:')[1] != null)
                    {
                      
                        var titularFormatado = elmFormatado.split('NOMBRE:')[1].toString().trimLeft();
                        this.data.contrato.nombre_titular = titularFormatado;
                        this.formataCamposMayuscula('nombre_titular');
                        this.data.contrato.nombre_titular = this.eliminarDiacriticosEs(this.data.contrato.nombre_titular.toString());
    
                    }
    
                  } catch (error) {
                    
                  }
                  
              }
              else if (elmFormatado.includes('AUTOCONSUMO:'))
              {
                
                  try {
                    
                    if (elmFormatado.split('AUTOCONSUMO:')[1] != null)
                    {
                                              
                        this.data.contrato.autoconsumo = elmFormatado.split('AUTOCONSUMO:')[1].toString().trimLeft();
                        this.formataCamposMayuscula('autoconsumo');
                        this.data.contrato.autoconsumo = this.eliminarDiacriticosEs(this.data.contrato.autoconsumo.toString());
    
                    }
    
                  } catch (error) {
                    
                  }
                  
              }
              else if (elmFormatado.includes('ENVÍO DE FACTURAS:'))
              {
                
                  try {
                    
                    if (elmFormatado.split('ENVÍO DE FACTURAS:')[1] != null)
                    {
                      
                        if (elmFormatado.split('ENVÍO DE FACTURAS:')[1].toString().trimLeft().toUpperCase().includes("E-MAIL"))
                            this.data.contrato.texto_tipo_impresion = "E";
                        else if (elmFormatado.split('ENVÍO DE FACTURAS:')[1].toString().trimLeft().toUpperCase().includes("PAPEL"))
                            this.data.contrato.texto_tipo_impresion = "P";
                        else 
                            this.data.contrato.texto_tipo_impresion = "E";
    
                    }
    
                  } catch (error) {
                    
                  }
                  
              }
              else if (elmFormatado.includes('ENVÍO CONTRATO:'))
              {
                
                  try {
                    
                    if (elmFormatado.split('ENVÍO CONTRATO:')[1] != null)
                    {
                      
                        if (elmFormatado.split('ENVÍO CONTRATO:')[1].toString().trimLeft().toUpperCase().includes("PAPEL"))
                            this.data.contrato.envio_contrato = "P";
                        else if (elmFormatado.split('ENVÍO CONTRATO:')[1].toString().trimLeft().toUpperCase().includes("LOGALTY"))
                            this.data.contrato.envio_contrato = "L";
                        else 
                            this.data.contrato.envio_contrato = "L";
    
                        this.changeEnvioContrato(this.data.contrato.envio_contrato);

                    }
    
                  } catch (error) {
                    
                  }
                  
              }
              else if (elmFormatado.includes('APELLIDO:'))
              {
                
                  try {
                    
                    if (elmFormatado.split('APELLIDO:')[1] != null)
                    {
                     
                        var titularFormatado = elmFormatado.split('APELLIDO:')[1].toString().trimLeft();
                       
                        this.data.contrato.apellido1_titular = titularFormatado.toString();
                        this.formataCamposMayuscula('apellido1_titular');
                        this.data.contrato.apellido1_titular = this.eliminarDiacriticosEs(this.data.contrato.apellido1_titular.toString());                     
                      
                    }
    
                  } catch (error) {
                    
                  }
                  
              }
              else if (elmFormatado.includes('APELLIDO2:'))
              {
                
                  try {
                    
                    if (elmFormatado.split('APELLIDO2:')[1] != null)
                    {
                     
                        var titularFormatado = elmFormatado.split('APELLIDO2:')[1].toString().trimLeft();
                       
                        this.data.contrato.apellido2_titular = titularFormatado.toString();
                        this.formataCamposMayuscula('apellido2_titular');
                        this.data.contrato.apellido2_titular = this.eliminarDiacriticosEs(this.data.contrato.apellido2_titular.toString());                     
                      
                    }
    
                  } catch (error) {
                    
                  }
                  
              }
              else if (elmFormatado.includes('IDIOMA CONTRATO:'))
              {

                
                  try {
                    
                    if (elmFormatado.split('IDIOMA CONTRATO:')[1] != null)
                    {
                      
                        var idioma = elmFormatado.split('IDIOMA CONTRATO:')[1].toString().trimLeft().toUpperCase().replace(/[^\x00-\x7F]/g, "");
                      
                        if (idioma.includes("SPANISH"))
                          this.data.contrato.idioma_preferido = "ES";
                        else if (idioma.includes("ENGLISH"))
                          this.data.contrato.idioma_preferido = "EN";
                        else if (idioma.includes("CATALAN"))
                          this.data.contrato.idioma_preferido = "CA";
                        else 
                          this.data.contrato.idioma_preferido = "ES";
                      
    
    
                    }
    
                  } catch (error) {
                    
                  }
                  
              }
              else if (elmFormatado.includes('DNINIE:'))
              {
                  try {
                    if (elmFormatado.split('DNINIE:')[1] != null)
                        this.data.contrato.numero_documento = elmFormatado.split('DNINIE:')[1].trimLeft();    
                        this.data.contrato.numero_documento = this.data.contrato.numero_documento.toString().replace(/[^\x00-\x7F]/g, ""); 
                        this.datosTitularFormGroup.controls["DNICtrl"].value(this.data.contrato.numero_documento);
                  } catch (error) {
                    
                  }              
              }
              else if (elmFormatado.includes('CAMBIOS:'))
              {
                  try {
                    if (elmFormatado.split('CAMBIOS:')[1] != null)
                        this.data.contrato.cambios = elmFormatado.split('CAMBIOS:')[1].trimLeft().toString().toUpperCase();                            
                  } catch (error) {
                    
                  }              
              }
              else if (elmFormatado.includes('IBAN:'))
              {
                  try {
                      if (element.split('IBAN:')[1] != null)
                      {
                          this.data.contrato.IBAN_titular = element.split('IBAN:')[1].trimLeft();                            
                          this.formataCampoIBAN();
                          this.data.contrato.IBAN_titular = this.data.contrato.IBAN_titular.toString().replace(/[^\x00-\x7F]/g, "");                         
                      } 
                  } catch (error) {
                    
                  }              
                    
              }
              else if (elmFormatado.includes('TELÉFONO:'))
              {
                  
                  try {
                    if (elmFormatado.split('TELÉFONO:')[1] != null)
                    {
                        
                        this.data.contrato.numero_telefono_titular = elmFormatado.split('TELÉFONO:')[1].trimLeft();     
                        this.data.contrato.numero_telefono_titular = this.data.contrato.numero_telefono_titular.toString().replace(/[^\x00-\x7F]/g, "");                         
                                         
                    } 
                  } catch (error) {
                    
                  }              
                    
              }
              else if (elmFormatado.includes('EMAIL:'))
              {
                  try {
                    if (elmFormatado.split('EMAIL:')[1] != null)
                    {
                        this.data.contrato.correo_titular = elmFormatado.split('EMAIL:')[1].trim();           
                        this.data.contrato.correo_titular = this.data.contrato.correo_titular.toString().toLowerCase();    
                        this.data.contrato.correo_titular = this.data.contrato.correo_titular.toString().replace(/[^\x00-\x7F]/g, "");
                    } 
                  } catch (error) {
                    
                  }              
                    
              }
            
              else if (elmFormatado.includes('DIRECCIÓN:'))
              {
                  
                  try {
                      if (elmFormatado.split('DIRECCIÓN:')[1] != null)
                      {
                          //aqui comes en direccion if ()
                          var direcciones = elmFormatado.split('DIRECCIÓN:')[1].split(','); 
                          
                          if (direcciones[0] != null)
                          {
                              //alert(this.convert_accented_characters(this.data.contrato.direccion));
                            //  this.data.contrato.direccion = this.convert_accented_characters(this.data.contrato.direccion);
                              this.data.contrato.direccion = this.eliminarDiacriticosEs(direcciones[0]);
                              this.formataCamposMayuscula('direccion');
                             // this.data.contrato.direccion = this.data.contrato.direccion.toString().replace(/[^\x00-\x7F]/g, "");
                              this.data.contrato.direccion_facturacion = this.data.contrato.direccion;
                          }      
                          if (direcciones[1] != null)
                          {
                              
                              this.data.contrato.numero = this.eliminarDiacriticosEs(direcciones[1]);
                              //this.data.contrato.numero = this.data.contrato.numero.toString().replace(/[^\x00-\x7F]/g, "");
                              this.data.contrato.numero_facturacion = this.data.contrato.numero;

                              //alert("<<<<" + this.data.contrato.numero);
                          }                    
                          if (direcciones[2] != null)
                          {
                              this.data.contrato.aclarador_titular = this.eliminarDiacriticosEs(direcciones[2]);
                              this.formataCamposMayuscula('aclarador_titular');
                              //this.data.contrato.aclarador_titular = this.data.contrato.aclarador_titular.toString().replace(/[^\x00-\x7F]/g, "");
                              this.data.contrato.aclarador_facturacion = this.data.contrato.aclarador_titular;
                          }                                                                        
                          if (direcciones[3] != null)
                          {
                              this.data.contrato.codigo_postal = this.eliminarDiacriticosEs(direcciones[3]);
                              this.data.contrato.codigo_postal = this.data.contrato.codigo_postal;
                              this.data.contrato.codigo_postal_facturacion = this.data.contrato.codigo_postal;
                          }                        
                          if (direcciones[4] != null)
                          {
                              this.data.contrato.ciudad = this.eliminarDiacriticosEs(direcciones[4]);
                              this.formataCamposMayuscula('ciudad');
                              //this.data.contrato.ciudad = this.data.contrato.ciudad.toString().replace(/[^\x00-\x7F]/g, "");
                              this.data.contrato.ciudad_facturacion = this.data.contrato.ciudad;
                          }   
                          if (direcciones[5] != null)                     
                          {
                            
                            this.data.contrato.provincia = this.eliminarDiacriticosEs(direcciones[5]);
                            this.formataCamposMayuscula('provincia');
                            //this.data.contrato.provincia = this.data.contrato.provincia.toString().replace(/[^\x00-\x7F]/g, "");
                            this.data.contrato.provincia_facturacion = this.data.contrato.provincia;
                          }
                                          
                      } 
                  } catch (error) {
                    
                  }              
                    
              }
              else if (elmFormatado.includes('CUPS:'))
              {
                  try {
                    if (elmFormatado.split('CUPS:')[1] != null)
                    {
                        this.data.contrato.CUPS = elmFormatado.split('CUPS:')[1].trim();        
                        this.data.contrato.CUPS = this.data.contrato.CUPS.trim();
                        this.data.contrato.CUPS = this.data.contrato.CUPS.toString().replace(/[^\x00-\x7F]/g, "");                         
                        
                        this.CUPSFormGroup.controls["CUPSCtrl"].value(this.data.contrato.CUPS);
                        this.CUPSFormGroup.controls["CUPSCtrl"].markAsTouched();
                        this.CUPSFormGroup.markAllAsTouched();
                                         
                    } 
                  } catch (error) {
                    
                  }              
                    
              }
              else if (elmFormatado.includes('CODIGO PROMOCIONAL:'))
              {            
                  try {
                    if (elmFormatado.split('CODIGO PROMOCIONAL:')[1] != null)
                        this.data.contrato.codigo_promocional = elmFormatado.split('CODIGO PROMOCIONAL:')[1].trim();                                                                                     
                        this.formataCamposMayuscula('codigo_promocional');
                  } catch (error) {
                    
                  }              
                    
              }
              else if (elmFormatado.includes('CÓDIGO PROMOCIONAL:'))
              {            
                  try {
                    if (elmFormatado.split('CÓDIGO PROMOCIONAL:')[1] != null)
                        this.data.contrato.codigo_promocional = elmFormatado.split('CÓDIGO PROMOCIONAL:')[1].trim();                                                                                     
                        this.formataCamposMayuscula('codigo_promocional');
                  } catch (error) {
                    
                  }              
                    
              }
              /*
              else if (elmFormatado.includes('TARIFA:'))
              {
                  try {
                    if (elmFormatado.split('TARIFA:')[1] != null)
                    {
      
                      if (elmFormatado.split('TARIFA:')[1].includes('2'))
                      {
                          this.data.contrato.tarifa = "2.0TD";
                      }
                      else if (elmFormatado.split('TARIFA:')[1].includes('3'))
                      {
                          this.data.contrato.tarifa = "3.0TD";
                      }               
                                         
                    } 
                  } catch (error) {
                    
                  }              
                    
              }
              */
              else if (elmFormatado.includes('TARIFA:'))
              {
                                    
                    if (elmFormatado.split('TARIFA:')[1] != null)
                    {
                        
                        if(elmFormatado.split('TARIFA:')[1].toUpperCase().includes('24H 0122'))
                        {
                            this.data.contrato.tarifa = "2.0TD";
                            this.obtenerGruposTarifa();
                            this.data.contrato.tarifa_grupo = "1";
                        }                            
                        else if((elmFormatado.split('TARIFA:')[1].toUpperCase().includes('24H PRIMER MES 0122')) || (elmFormatado.split('TARIFA:')[1].toUpperCase().includes('24H VAS 0122')))
                        {
                            this.data.contrato.tarifa = "2.0TD";
                            this.obtenerGruposTarifa();
                            this.data.contrato.tarifa_grupo = "2";
                        }
                            
                        else if(elmFormatado.split('TARIFA:')[1].toUpperCase().includes('FIJA 0122'))
                        {
                            this.data.contrato.tarifa = "2.0TD";
                            this.obtenerGruposTarifa();
                            this.data.contrato.tarifa_grupo = "3";
                        }                            
                        else if((elmFormatado.split('TARIFA:')[1].toUpperCase().includes('FIJA PRIMER MES 0122')) || (elmFormatado.split('TARIFA:')[1].toUpperCase().includes('FIJA VAS 0122')))
                        {
                            this.data.contrato.tarifa = "2.0TD";
                            this.obtenerGruposTarifa();
                            this.data.contrato.tarifa_grupo = "4";                            
                        }                            
                        else if(elmFormatado.split('TARIFA:')[1].toUpperCase().includes('TRANSPARENTE'))
                        {
                            this.data.contrato.tarifa = "2.0TD";
                            this.obtenerGruposTarifa();
                            this.data.contrato.tarifa_grupo = "5";                            
                        }                            
                        else if((elmFormatado.split('TARIFA:')[1].toUpperCase().includes('TRANSPARENTE PRIMER MES')) || (elmFormatado.split('TARIFA:')[1].toUpperCase().includes('TRANSPARENTE VAS')))
                        {
                            this.data.contrato.tarifa = "2.0TD";
                            this.obtenerGruposTarifa();
                            this.data.contrato.tarifa_grupo = "6";
                            
                        }                            
                        else if(elmFormatado.split('TARIFA:')[1].toUpperCase().includes('ESTABLE'))//3.0
                        {
                            this.data.contrato.tarifa = "3.0TD";
                            this.obtenerGruposTarifa();
                            this.data.contrato.tarifa_grupo = "7";
                        }                            
                        else if(elmFormatado.split('TARIFA:')[1].toUpperCase().includes('REGULAR'))
                        {
                            this.data.contrato.tarifa = "3.0TD";
                            this.obtenerGruposTarifa();
                            this.data.contrato.tarifa_grupo = "8";
                        }                            
                        else if((elmFormatado.split('TARIFA:')[1].toUpperCase().includes('INDEXADA 4')) || (elmFormatado.split('TARIFA:')[1].toUpperCase().includes('INDEXADA4')))
                        {
                            this.data.contrato.tarifa = "3.0TD";
                            this.obtenerGruposTarifa();
                            this.data.contrato.tarifa_grupo = "9";
                        }                            
                        else if((elmFormatado.split('TARIFA:')[1].toUpperCase().includes('INDEXADA 5')) || (elmFormatado.split('TARIFA:')[1].toUpperCase().includes('INDEXADA5')))
                        {
                            this.data.contrato.tarifa = "3.0TD";
                            this.obtenerGruposTarifa();
                            this.data.contrato.tarifa_grupo = "10";
                        }                            
                        else if((elmFormatado.split('TARIFA:')[1].toUpperCase().includes('INDEXADA 6')) || (elmFormatado.split('TARIFA:')[1].toUpperCase().includes('INDEXADA6')))
                        {
                            this.data.contrato.tarifa = "3.0TD";
                            this.obtenerGruposTarifa();
                            this.data.contrato.tarifa_grupo = "11";
                        }                            
                        else if((elmFormatado.split('TARIFA:')[1].toUpperCase().includes('INDEXADA 10')) || (elmFormatado.split('TARIFA:')[1].toUpperCase().includes('INDEXADA10')))
                        {
                            this.data.contrato.tarifa = "3.0TD";
                            this.obtenerGruposTarifa();
                            this.data.contrato.tarifa_grupo = "12";
                        }                            
                        else if((elmFormatado.split('TARIFA:')[1].toUpperCase().includes('INDEXADA 15')) || (elmFormatado.split('TARIFA:')[1].toUpperCase().includes('INDEXADA15')))
                        {
                            this.data.contrato.tarifa = "3.0TD";
                            this.obtenerGruposTarifa();
                            this.data.contrato.tarifa_grupo = "13";
                        }
                            
                      
                        //alert(this.data.contrato.tarifa_grupo);
                        this.changeGrupoTarifa(this.data.contrato.tarifa_grupo);
                            
                                        
                    }
                    
              }
              else if (elmFormatado.includes('PROMO:'))
              {
                  try {
                    if (elmFormatado.split('PROMO:')[1] != null)
                    {
                        this.data.contrato.codigo_promocional = elmFormatado.split('PROMO:')[1];                             
                                         
                    } 
                  } catch (error) {
                    
                  }              
                    
              }
              else if (elmFormatado.includes('NOMBRE REPRESENTANTE:'))
              {
                  try {
                    if (elmFormatado.split('NOMBRE REPRESENTANTE:')[1] != null)
                    {
                        this.data.contrato.agente = elmFormatado.split('NOMBRE REPRESENTANTE:')[1];                             
                        this.formataCamposMayuscula('agente');
                                         
                    } 
                  } catch (error) {
                    
                  }              
                    
              }
              else if (elmFormatado.includes('RAZÓN SOCIAL:'))
              {
                  try {
                    if (elmFormatado.split('RAZÓN SOCIAL:')[1] != null)
                    {
                        this.data.contrato.nombre_empresa = elmFormatado.split('RAZÓN SOCIAL:')[1];                             
                        this.formataCamposMayuscula('nombre_empresa');
                                         
                    } 
                  } catch (error) {
                    
                  }              
                    
              }
              else if (elmFormatado.includes('AGENTE:'))
              {
                  
               
                  try {
                    if (elmFormatado.split('AGENTE:')[1] != null)
                    {
                        this.data.contrato.codigo_agente = elmFormatado.split('AGENTE:')[1].trimLeft();            
                        this.data.contrato.codigo_agente = this.eliminarDiacriticosEs(this.data.contrato.codigo_agente.toString());
                        this.datosInternosFormGroup.controls["agenteCtrl"].value(this.data.contrato.codigo_agente);
                                         
                    } 
                  } catch (error) {
                    
                  }              
                    
              }
              else if (elmFormatado.includes('CNAE:'))
              {
                                 
                  try {
                    if (elmFormatado.split('CNAE:')[1] != null)
                    {
                        this.data.contrato.codigo_cnae = elmFormatado.split('CNAE:')[1].trimLeft();                                                                             
                        this.evaluaChkPrimeraResidenciaSegunCodigoCNAE();
                    } 
                  } catch (error) {
                    
                  }              
                    
              }
              /*
              else if (element.toString().includes('TIPOIMPRESION:'))
              {
                  try {
                    if (element.split('TIPOIMPRESION:')[1] != null)
                    {
                        this.data.contrato.texto_tipo_impresion = element.split('TIPOIMPRESION:')[1];                             
                        
                                         
                    } 
                  } catch (error) {
                    
                  }              
                    
              }
              */
    
              this.changeGrupoTarifa(this.data.contrato.tarifa_grupo);
    
            });   

            this.datosTitularFormGroup.markAllAsTouched();
            this.CUPSFormGroup.markAllAsTouched();
            this.datosInternosFormGroup.markAllAsTouched();
            this.datosSuministroFormGroup.markAllAsTouched();
            this.datosFacturacionFormGroup.markAllAsTouched();

            this.spinner.hide();
        } catch (error) {
            
        }
    }

    obtenerProvinciaSegunCodigoPostal()
    {
        
        try {
          var codigo = this.data.contrato.codigo_postal.substring(0,2);        
          if(codigo.toString() == "01")
              this.data.contrato.provincia = "ÁLAVA";            
          else if(codigo.toString() == "02")
              this.data.contrato.provincia = "ALBACETE";            
          else if(codigo.toString() == "03")
              this.data.contrato.provincia = "ÁLAVA";            
          else if(codigo.toString() == "04")
              this.data.contrato.provincia = "ALMERÍA";            
          else if(codigo.toString() == "05")
              this.data.contrato.provincia = "ÁVILA";            
          else if(codigo.toString() == "06")
              this.data.contrato.provincia = "BADAJOZ";            
          else if(codigo.toString() == "07")
              this.data.contrato.provincia = "BALEARES";            
          else if(codigo.toString() == "08")
              this.data.contrato.provincia = "BARCELONA";                          
          else if(codigo.toString() == "09")
              this.data.contrato.provincia = "BURGOS";            
          else if(codigo.toString() == "10")
              this.data.contrato.provincia = "CÁCERES";            
          else if(codigo.toString() == "11")
              this.data.contrato.provincia = "CÁDIZ";            
          else if(codigo.toString() == "12")
              this.data.contrato.provincia = "CASTELLÓN";            
          else if(codigo.toString() == "13")
              this.data.contrato.provincia = "CIUDAD REAL";            
          else if(codigo.toString() == "14")
              this.data.contrato.provincia = "CÓRDOBA";            
          else if(codigo.toString() == "15")
              this.data.contrato.provincia = "LA CORUÑA";            
          else if(codigo.toString() == "16")
              this.data.contrato.provincia = "CUENCA";            
          else if(codigo.toString() == "17")
              this.data.contrato.provincia = "GIRONA";            
          else if(codigo.toString() == "18")
              this.data.contrato.provincia = "GRANADA";            
          else if(codigo.toString() == "19")
              this.data.contrato.provincia = "GUADALAJARA";            
          else if(codigo.toString() == "20")
              this.data.contrato.provincia = "GUIPÚZCOA";            
          else if(codigo.toString() == "21")
              this.data.contrato.provincia = "HUELVA";            
          else if(codigo.toString() == "22")
              this.data.contrato.provincia = "HUESCA";            
          else if(codigo.toString() == "23")
              this.data.contrato.provincia = "JAÉN";            
          else if(codigo.toString() == "24")
              this.data.contrato.provincia = "LEÓN";            
          else if(codigo.toString() == "25")
              this.data.contrato.provincia = "LLEIDA";            
          else if(codigo.toString() == "26")
              this.data.contrato.provincia = "LA RIOJA";            
          else if(codigo.toString() == "27")
              this.data.contrato.provincia = "LUGO";            
          else if(codigo.toString() == "28")
              this.data.contrato.provincia = "MADRID";            
          else if(codigo.toString() == "29")
              this.data.contrato.provincia = "MÁLAGA";            
          else if(codigo.toString() == "30")
              this.data.contrato.provincia = "MURCIA";            
          else if(codigo.toString() == "31")
              this.data.contrato.provincia = "NAVARRA";            
          else if(codigo.toString() == "32")
              this.data.contrato.provincia = "OURENSE";            
          else if(codigo.toString() == "33")
              this.data.contrato.provincia = "ASTURIAS";            
          else if(codigo.toString() == "34")
              this.data.contrato.provincia = "PALENCIA";            
          else if(codigo.toString() == "35")
              this.data.contrato.provincia = "LAS PALMAS";            
          else if(codigo.toString() == "36")
              this.data.contrato.provincia = "PONTEVEDRA";            
          else if(codigo.toString() == "37")
              this.data.contrato.provincia = "SALAMANCA";            
          else if(codigo.toString() == "38")
              this.data.contrato.provincia = "SANTA CRUZ DE TENERIFE";            
          else if(codigo.toString() == "39")
              this.data.contrato.provincia = "CANTABRIA";            
          else if(codigo.toString() == "40")
              this.data.contrato.provincia = "SEGOVIA";            
          else if(codigo.toString() == "41")
              this.data.contrato.provincia = "SEVILLA";            
          else if(codigo.toString() == "42")
              this.data.contrato.provincia = "SORIA";            
          else if(codigo.toString() == "43")
              this.data.contrato.provincia = "TARRAGONA";            
          else if(codigo.toString() == "44")
              this.data.contrato.provincia = "TERUEL";            
          else if(codigo.toString() == "45")
              this.data.contrato.provincia = "TOLEDO";            
          else if(codigo.toString() == "46")
              this.data.contrato.provincia = "VALENCIA";            
          else if(codigo.toString() == "47")
              this.data.contrato.provincia = "VALLADOLID";            
          else if(codigo.toString() == "48")
              this.data.contrato.provincia = "VIZCAYA";            
          else if(codigo.toString() == "49")
              this.data.contrato.provincia = "ZAMORA";            
          else if(codigo.toString() == "50")
              this.data.contrato.provincia = "ZARAGOZA";            
          else if(codigo.toString() == "51")
              this.data.contrato.provincia = "CEUTA";            
          else if(codigo.toString() == "52")
              this.data.contrato.provincia = "MELILLA";            

            this.formataCamposMayuscula('provincia');

        } catch (error) {
          
        }        
    }

    formataCamposMayuscula(campo)
    {
        //MAYÚSCULAS
        this.data.contrato[campo] = this.data.contrato[campo].toUpperCase();

        //ACENTOS
        const acentos = {'Á':'A','À':'A','É':'E','È':'E','Í':'I','Ì':'I','Ó':'O','Ò':'O','Ú':'U','Ù':'U'};
        this.data.contrato[campo] = this.data.contrato[campo].split('').map( letra => acentos[letra] || letra).join('').toString();	

    }

    
     quote(string) {

        // If the string contains no control characters, no quote characters, and no
        // backslash characters, then we can safely slap some quotes around it.
        // Otherwise we must also replace the offending characters with safe escape
        // sequences.

        var escapable = /[\\\"\x00-\x1f\x7f-\uffff]/g,
        meta = {    // table of character substitutions
            '\b': '\\b',
            '\t': '\\t',
            '\n': '\\n',
            '\f': '\\f',
            '\r': '\\r',
            '"' : '\\"',
            '\\': '\\\\'
        };
        
                escapable.lastIndex = 0;
                return escapable.test(string) ?
                    '"' + string.replace(escapable, function (a) {
                        var c = meta[a];
                        return typeof c === 'string' ? c :
                            '\\u' + ('0000' + a.charCodeAt(0).toString(16)).slice(-4);
                    }) + '"' :
                    '"' + string + '"';
            }

    
    descargarPDF()
    {
        //var fuente = "calibri";
        //var estilo = "normal";
        var rTexto=0;
        var gTexto=0;
        var bTexto=0;

        var contract = {
            numeracion : this.data.contrato.numeracion,
            codigo_agente:this.data.contrato.codigo_agente,
            nombre_titular:this.data.contrato.nombre_titular,
            apellido1_titular:this.data.contrato.apellido1_titular,
            apellido2_titular:this.data.contrato.apellido2_titular,
            ciudad:this.data.contrato.ciudad,
            ciudad_facturacion:this.data.contrato.ciudad_facturacion,
            provincia:this.data.contrato.provincia,
            provincia_facturacion:this.data.contrato.provincia_facturacion,
            direccion:this.data.contrato.direccion,
            direccion_facturacion:this.data.contrato.direccion_facturacion,
            numero:this.data.contrato.numero,
            numero_facturacion:this.data.contrato.numero_facturacion,
            codigo_postal:this.data.contrato.codigo_postal,
            codigo_postal_facturacion:this.data.contrato.codigo_postal_facturacion,
            aclarador:this.data.contrato.aclarador,
            aclarador_facturacion:this.data.contrato.aclarador_facturacion,
            CUPS:this.data.contrato.CUPS,
            correo_titular:this.data.contrato.correo_titular,
            numero_telefono_titular:this.data.contrato.numero_telefono_titular,
            potencia_P1:this.data.contrato.potencia_P1,
            potencia_P2:this.data.contrato.potencia_P2,
            potencia_P3:this.data.contrato.potencia_P3,
            potencia_P4:this.data.contrato.potencia_P4,
            potencia_P5:this.data.contrato.potencia_P5,
            potencia_P6:this.data.contrato.potencia_P6,
            energia_P1:this.data.contrato.energia_P1,
            energia_P2:this.data.contrato.energia_P2,
            energia_P3:this.data.contrato.energia_P3,
            energia_P4:this.data.contrato.energia_P4,
            energia_P5:this.data.contrato.energia_P5,
            energia_P6:this.data.contrato.energia_P6,
            IBAN_titular:this.data.contrato.IBAN_titular};

           

var myHeaders = new Headers();
var raw = JSON.stringify(contract);

var requestOptions = {
  method: 'Post',
  headers: {
                'Accept': "application/json, text/plain, */*",
                'Content-Type': "application/json;charset=utf-8"
            },
  redirect: 'follow',
    body:JSON.stringify(this.data.contrato
    )
};
this.spinner.show();

fetch("https://apiescandinava.site/html/emas/pdf", requestOptions as any)
.then((res) => res.blob())
  .then((blob) => URL.createObjectURL(blob))
  .then((href) => {
    Object.assign(document.createElement('a'), {
      href,
      download: this.data.contrato.nombre_titular + ' ' + this.data.contrato.apellido1_titular + ' ' + this.data.contrato.apellido2_titular + ' ' + this.data.contrato.CUPS + '.pdf',
    }).click();
    this.spinner.hide();
  });


             console.log(this.data.contrato);

        
    }

     bin2String(array) {
        return String.fromCharCode.apply(String, array);
      }

     string2Bin(str) {
        var result = [];
        for (var i = 0; i < str.length; i++) {
          result.push(str.charCodeAt(i).toString(2));
        }
        return result;
      }

    cancelaDialogo(): void {            

            
      this.dialogRef.close();
    }

    formataCampoIBAN()
    {  

        this.data.contrato.IBAN_titular = this.data.contrato.IBAN_titular.replace(/\s/g, "");        
        this.datosTitularFormGroup.controls.IBANCtrl.setValue(this.data.contrato.IBAN_titular.replace(/\s/g, ""));
    }

    onPasteCUPS()//event: ClipboardEvent
    {
        /*
        this.data.contrato.CUPS = "";
        let clipboardData = event.clipboardData;
        let pastedText = clipboardData.getData('text');              
        this.data.contrato.CUPS = pastedText;
        alert(this.data.contrato.CUPS);
        */
       //this.data.contrato.CUPS = "ES0031405453470013LM0F​";
        this.CUPSFormGroup.controls['CUPSCtrl'].setErrors(null);
        this.wrapperValidadorCups();
    }
   

    async wrapperValidadorCups()
    {
      
      
        this.data.contrato.CUPS = this.data.contrato.CUPS.trim().toUpperCase();
        this.data.contrato.CUPS = this.data.contrato.CUPS.replace(/[^\x00-\x7F]/g, "");
        var resultadoValidacionCups = await this.valida_cups();
        //alert("RESULTAT->" + JSON.stringify(resultadoValidacionCups));
        
        if (!resultadoValidacionCups["success"])
        {
            //this.CUPSFormGroup.controls["CUPSCtrl"].setErrors({'error' : true });
            //this.CUPSFormGroup.controls['CUPSCtrl'].setErrors({'incorrect': true});
            
            this.CUPSFormGroup.controls['CUPSCtrl'].setErrors({'incorrect': true});

            this.codigoCupsCorrecto = false;
        }
        else 
        {
          
            //this.CUPSFormGroup.controls["CUPSCtrl"].setErrors({'error' : false });            
            //this.CUPSFormGroup.controls['CUPSCtrl'].setErrors({'incorrect': false});
            this.CUPSFormGroup.controls['CUPSCtrl'].setErrors(null);
            
            this.codigoCupsCorrecto = true;
        }

        this.CUPSFormGroup.controls['CUPSCtrl'].markAsTouched();
        

        
    }

    goBack(stepper: MatStepper){
      stepper.previous();
  }
  
  goForward(stepper: MatStepper){
      stepper.next();
  }

    obtenerDatosNemonByCUPS()
    {

        this.http.getNemonByCUPS(this.CUPSFormGroup.controls["CUPSCtrl"].value).then(
          (datosNemon) => {
            console.log("DATOS NEMON --> " + JSON.stringify(datosNemon));

            this.data.contrato.direccion = datosNemon["suministros"][0]["Direccion_Suministro"];
            this.data.contrato.codigo_postal = datosNemon["suministros"][0]["Cod_Postal_Suministro"];
            this.data.contrato.ciudad = datosNemon["suministros"][0]["Localidad_Suministro"];
            this.data.contrato.provincia = datosNemon["suministros"][0]["Provincia_Suministro"];
            this.data.contrato.nombre_titular = datosNemon["suministros"][0]["Nombre_Titular"];
            this.data.contrato.apellido1_titular = datosNemon["suministros"][0]["Apellido_Titular"];
            this.data.contrato.numero_telefono_titular = datosNemon["suministros"][0]["Telefono_Titular"];
            this.data.contrato.numero_documento = datosNemon["suministros"][0]["CIF_Titular"];
            this.data.contrato.tarifa = datosNemon["suministros"][0]["Tarifa"];

            this.tarifas.forEach(element => {
              if (element.label == datosNemon["suministros"][0]["Tarifa"])
                this.data.contrato.tarifa = element.value;
            });

            this.data.contrato.potencia_P1 = datosNemon["suministros"][0]["Pot_Cont_P1"];
            this.data.contrato.potencia_P2 = datosNemon["suministros"][0]["Pot_Cont_P2"];
            this.data.contrato.potencia_P3 = datosNemon["suministros"][0]["Pot_Cont_P3"];
            this.data.contrato.potencia_P4 = datosNemon["suministros"][0]["Pot_Cont_P4"];
            this.data.contrato.potencia_P5 = datosNemon["suministros"][0]["Pot_Cont_P5"];
            this.data.contrato.potencia_P6 = datosNemon["suministros"][0]["Pot_Cont_P6"];

            
            this.data.contrato.energia_P1 = datosNemon["suministros"][0]["kWhAnual_pctge_p1"];
            this.data.contrato.energia_P2 = datosNemon["suministros"][0]["kWhAnual_pctge_p2"];
            this.data.contrato.energia_P3 = datosNemon["suministros"][0]["kWhAnual_pctge_p3"];
            this.data.contrato.energia_P4 = datosNemon["suministros"][0]["kWhAnual_pctge_p4"];
            this.data.contrato.energia_P5 = datosNemon["suministros"][0]["kWhAnual_pctge_p5"];
            this.data.contrato.energia_P6 = datosNemon["suministros"][0]["kWhAnual_pctge_p6"];

            this.data.contrato["codigo_cnae"] = datosNemon["suministros"][0]["cnae_code"];
            this.evaluaChkPrimeraResidenciaSegunCodigoCNAE();
            
          }
        )
        
    }

    valida_cups(){

      
      let CUPS = this.CUPSFormGroup.controls["CUPSCtrl"].value;      
      var RegExPattern =/^[a-zA-Z]{2}[0-9]{16}[a-zA-Z]{2}([0-9][A-Za-z])?$/;

      return new Promise((resolve) => {

          if (CUPS.length>22) {
              resolve({success: false, code: 1, msg:'Demasiado largo' }); //Demasiado largo
          }
          
          if (CUPS.length<20) {
              resolve({success: false, code: 2, msg:'Demasiado corto' }); //Demasiado corto
          }
          
          if (!CUPS.match(RegExPattern)) {
              resolve({success: false, code: 3, msg:'Estructura no válida' }); //Estructura no válida
          }
          
          var CUPS_16 = parseInt(CUPS.substr(2,16));
          var control = CUPS.substr(18,2).toUpperCase();
          var letters = Array('T','R','W','A','G','M','Y','F','P','D','X','B','N','J','Z','S','Q','V','H','L','C','K','E');
          
          var R0 = CUPS_16 % 529;
          var cont_C = Math.floor(R0/23);
          var cont_R = R0 % 23;
          
          var dc1 = letters[cont_C];
          var dc2 = letters[cont_R];
          var status = (control === dc1+dc2);
          
          if(!status){
              resolve({success: false, code: 4, msg:'Dígitos de control no válidos, se esperaba '+dc1+dc2 }); //Los dígitos de control no son válidos
          }
          
            resolve({success: true});
          }

        ).then(
            (result) => {
              //alert("RESULT?? " + JSON.stringify(result));
              return result; 
            }
        
      )
      
    }
      

   ngOnInit()
   {

     
      
       //test
       /*
      this.http.getNemonByCUPS().then(
        (result) => {
          alert(JSON.stringify(result));
        }
      )*/
       //fins aqui
   }

   obtenerDatosHubspotByCUPS()
   {
      
     this.http.obtenerDatosContactoHubspotByCUPS(this.CUPSFormGroup.controls["CUPSCtrl"].value,localStorage.getItem('token')).then(
       (response) => {
         console.log(JSON.stringify(response))

         //alert(response["properties"]["potencia_contratada"]["value"]);
         //APELLIDOS         
         if (response["properties"]["lastname"] != null)
         {
            var apellidos = response["properties"]["lastname"]["value"].toString().split(" ");
            if (apellidos[0] != null)
            {
                this.data.contrato.apellido1_titular = apellidos[0];
                this.formataCamposMayuscula('apellido1_titular');
            }              
            if (apellidos[1] != null)
            {
                this.data.contrato.apellido2_titular = apellidos[1];
                this.formataCamposMayuscula('apellido2_titular');
            }
              
         }
         if (response["properties"]["Apellido1Titular"] != null)
         {
            this.data.contrato.apellido1_titular = response["properties"]["Apellido1Titular"]["value"];
            this.formataCamposMayuscula('apellido1_titular');
         }           
          if (response["properties"]["Apellido2Titular"] != null)
          {
            this.data.contrato.apellido2_titular = response["properties"]["Apellido2Titular"]["value"];
            this.formataCamposMayuscula('apellido2_titular');
          }            

         //NOMBRE TITULAR
         if (response["properties"]["firstname"] != null)
         {
            this.data.contrato.nombre_titular = response["properties"]["firstname"]["value"];
            this.formataCamposMayuscula('nombre_titular');
         }           
        else 
        {
          if (response["properties"]["nombreTitular"] != null)
          {
              this.data.contrato.nombre_titular = response["properties"]["nombreTitular"]["value"];
              this.formataCamposMayuscula('nombre_titular');
          }
        }

        //DIRECCION
        if(response["properties"]["address"] != null)
        {
            this.data.contrato.direccion = response["properties"]["address"]["value"];
            this.formataCamposMayuscula('direccion');
            this.data.contrato.direccion_facturacion = this.data.contrato.direccion;
        }
        else 
        {
            if(response["properties"]["address"] != null)
            {
                this.data.contrato.direccion = response["properties"]["address"]["value"];
                this.formataCamposMayuscula('direccion');
                this.data.contrato.direccion_facturacion = this.data.contrato.direccion;
            }              
        }
        //NUMERO DIRECCION
        if(response["properties"]["numerotitular"] != null)
        {
            this.data.contrato.numero = response["properties"]["numerotitular"]["value"];            
            this.data.contrato.numero_facturacion = this.data.contrato.numero;
        }  
        //ACLARADOR DIRECCION
        if(response["properties"]["aclaradortitular"] != null)
        {
            this.data.contrato.aclarador_titular = response["properties"]["aclaradortitular"]["value"];
            this.formataCamposMayuscula('aclarador_titular');
            this.data.contrato.aclarador_facturacion = this.data.contrato.aclarador_titular;
        }              
        //POBLACION
        if(response["properties"]["ciudadtitular"] != null)
        {
            this.data.contrato.ciudad = response["properties"]["ciudadtitular"]["value"];
            
            this.formataCamposMayuscula('ciudad');
            this.data.contrato.ciudad_facturacion = this.data.contrato.ciudad;
        }
        else 
        {
            if(response["properties"]["city"] != null)
            {
                this.data.contrato.ciudad = response["properties"]["city"]["value"];
                this.formataCamposMayuscula('ciudad');
                this.data.contrato.ciudad_facturacion = this.data.contrato.ciudad;
            }              
        }
        //CODIGO POSTAL
        if(response["properties"]["cptitular"] != null)
        {
            this.data.contrato.codigo_postal = response["properties"]["cptitular"]["value"];
            this.data.contrato.codigo_postal_facturacion = this.data.contrato.codigo_postal;
            this.obtenerProvinciaSegunCodigoPostal();
            this.data.contrato.provincia_facturacion = this.data.contrato.provincia;
        }
        else 
        {
            if(response["properties"]["zip"] != null)
            {
                this.data.contrato.codigo_postal = response["properties"]["zip"]["value"];
                this.data.contrato.codigo_postal_facturacion = this.data.contrato.codigo_postal;
                this.obtenerProvinciaSegunCodigoPostal();
                this.data.contrato.provincia_facturacion = this.data.contrato.provincia;
            }              
        }
        
        //NOMBRE EMPRESA
          if(response["properties"]["nombre_de_la_empresa"] != null)
          {
              this.data.contrato.nombre_empresa = response["properties"]["nombre_de_la_empresa"]["value"];
              this.formataCamposMayuscula('nombre_empresa');
          }
          else 
          {
              if(response["properties"]["razonsocialtitular"] != null)
              {
                  this.data.contrato.nombre_empresa = response["properties"]["razonsocialtitular"]["value"];
                  this.formataCamposMayuscula('nombre_empresa');
              }                
          }

          //TELEFONO 
          if(response["properties"]["tfnotitular"] != null)
          {
              this.data.contrato.numero_telefono_titular = response["properties"]["tfnotitular"]["value"];
          }
          else 
          {
              if(response["properties"]["phone"] != null)
                this.data.contrato.numero_telefono_titular = response["properties"]["phone"]["value"];
          }

          //CORREO
          if(response["properties"]["EmailTitular"] != null)
          {
              this.data.contrato.correo_titular = response["properties"]["EmailTitular"]["value"];
          }
          else 
          {
              if(response["properties"]["email"] != null)
                this.data.contrato.correo_titular = response["properties"]["email"]["value"];
          }
          //DNI
          if(response["properties"]["numero_de_documento"] != null)
          {
              this.data.contrato.numero_documento = response["properties"]["numero_de_documento"]["value"];
          }
          else 
          {
              if(response["properties"]["dni"] != null)
                this.data.contrato.numero_documento = response["properties"]["dni"]["value"];
          }
          //IBAN
          if(response["properties"]["IBAN"] != null)
          {
              this.data.contrato.IBAN_titular = response["properties"]["IBAN"]["value"];
          }
          
            //POTENCIA P1
            if(response["properties"]["potenciap1"] != null)
            {
                this.data.contrato.potencia_P1 = response["properties"]["potenciap1"]["value"];
            }
            //POTENCIA P2
            if(response["properties"]["potenciap2"] != null)
            {
                this.data.contrato.potencia_P2 = response["properties"]["potenciap2"]["value"];
            }
            //POTENCIA P3
            if(response["properties"]["potenciap3"] != null)
            {
                this.data.contrato.potencia_P3 = response["properties"]["potenciap3"]["value"];
            }
            //POTENCIA P4
            if(response["properties"]["potenciap4"] != null)
            {
                this.data.contrato.potencia_P4 = response["properties"]["potenciap4"]["value"];
            }
            //POTENCIA P5
            if(response["properties"]["potenciap5"] != null)
            {
                this.data.contrato.potencia_P5 = response["properties"]["potenciap5"]["value"];
            }
            //POTENCIA P6
            if(response["properties"]["potenciap6"] != null)
            {
                this.data.contrato.potencia_P6 = response["properties"]["potenciap6"]["value"];
            }
            //NOMBRE AGENTE
            if(response["properties"]["nombreagente"] != null)
            {
                
                    this.data.contrato.codigo_agente = response["properties"]["nombreagente"]["value"];
                    this.formataCamposMayuscula('agente');

                    /*
                this.http.obtenerOwnerHubspot(response["properties"]["NombreAgente"]["value"]).then(
                  (re3sult) => {
                    
                    this.data.contrato.agente = re3sult["firstName"] + " " + re3sult["lastName"];
                    this.formataCamposMayuscula('agente');
                  }
                )
                */
            }

           
            if(response["properties"]["hubspot_owner_id"] != null)
            {
                
                
                this.http.obtenerOwnerHubspot(response["properties"]["hubspot_owner_id"]["value"]).then(
                (re3sult) => {
                    
                    this.data.contrato.agente = re3sult["firstName"] + " " + re3sult["lastName"];
                    this.formataCamposMayuscula('agente');
                }
                )
            }
                  
            
            
            //IDIOMA
            if(response["properties"]["hs_language"] != null)
            {
              
                
                var idioma = response["properties"]["hs_language"]["value"].toString().trimLeft().toUpperCase().replace(/[^\x00-\x7F]/g, "");
                
                      
                if (idioma.includes("ES-") || (idioma == "ES"))
                  this.data.contrato.idioma_preferido = "ES";
                else if (idioma.includes("EN-") || (idioma == "EN") )
                  this.data.contrato.idioma_preferido = "EN";
                else if (idioma.includes("CA-") || (idioma == "CA"))
                  this.data.contrato.idioma_preferido = "CA";
                else 
                  this.data.contrato.idioma_preferido = "ES";

                //this.data.contrato.idioma_preferido = response["properties"]["idioma"]["value"];
                //alert("IDIOMA->" + response["properties"]["Idioma"]["value"]);
                
            }
          //CÓDIGO PROMOCIONAL
          if(response["properties"]["promocion"] != null)
          {
              this.data.contrato.codigo_promocional = response["properties"]["promocion"]["value"];                               
          }
          else 
          {
              if (response["properties"]["codigo_promocional"] != null)
              {
                  this.data.contrato.codigo_promocional = response["properties"]["codigo_promocional"]["value"];                               
              }
          }
          //TIPO IMPRESION
          if(response["properties"]["textotipoimpresion"] != null)
              this.data.contrato.texto_tipo_impresion = response["properties"]["textotipoimpresion"]["value"].substr(0,1).toUpperCase();
          //GRUPO TARIFA
          if(response["properties"]["tarifagrupo"] != null)
          {
              this.gruposTarifa.forEach(element => {
                if (element.label.includes(response["properties"]["tarifagrupo"]["value"]))
                {
                    this.data.contrato.tarifa_grupo = element.value;
                    this.changeGrupoTarifa(element.value);
                }
                
              });
              
              
          }
          if(response["properties"]["tarifa"] != null)
          {
              this.tarifas.forEach(element => {
                if (element.value.includes(response["properties"]["tarifa"]["value"]))
                    this.data.contrato.tarifa = response["properties"]["tarifa"]["value"];
              });
              
          }
          if(response["properties"]["potencia_contratada"] != null)
          {
              this.data.contrato.potencia_suministro = response["properties"]["potencia_contratada"]["value"];
          }
          if(response["properties"]["iban"] != null)
          {
              this.data.contrato.IBAN_titular = response["properties"]["iban"]["value"];
          }
          
          

          this.datosTitularFormGroup.markAllAsTouched();
          this.CUPSFormGroup.markAllAsTouched();
          this.datosInternosFormGroup.markAllAsTouched();
          this.datosSuministroFormGroup.markAllAsTouched();
          this.datosFacturacionFormGroup.markAllAsTouched();
                      
       }
     )
   }

   changeTarifa(valor)
   {
       //alert("VALOR " + valor);
       //Potencia2Ctrl
       if (valor == "2.0TD")
       {
            this.datosTarifaFormGroup.controls["Potencia2Ctrl"].disable();
            this.datosTarifaFormGroup.controls["Potencia4Ctrl"].disable();
            this.datosTarifaFormGroup.controls["Potencia5Ctrl"].disable();
            this.datosTarifaFormGroup.controls["Potencia6Ctrl"].disable();
            this.datosTarifaFormGroup.controls["Energia4Ctrl"].disable();
            this.datosTarifaFormGroup.controls["Energia5Ctrl"].disable();
            this.datosTarifaFormGroup.controls["Energia6Ctrl"].disable();

            this.datosSuministroFormGroup.controls["PotenciaSuministroP2Ctrl"].disable();
            this.datosSuministroFormGroup.controls["PotenciaSuministroP4Ctrl"].disable();
            this.datosSuministroFormGroup.controls["PotenciaSuministroP5Ctrl"].disable();
            this.datosSuministroFormGroup.controls["PotenciaSuministroP6Ctrl"].disable();
       }
       else 
       {
            this.datosTarifaFormGroup.controls["Potencia2Ctrl"].enable();
            this.datosTarifaFormGroup.controls["Potencia4Ctrl"].enable();
            this.datosTarifaFormGroup.controls["Potencia5Ctrl"].enable();
            this.datosTarifaFormGroup.controls["Potencia6Ctrl"].enable();
            this.datosTarifaFormGroup.controls["Energia4Ctrl"].enable();
            this.datosTarifaFormGroup.controls["Energia5Ctrl"].enable();
            this.datosTarifaFormGroup.controls["Energia6Ctrl"].enable();

            this.datosSuministroFormGroup.controls["PotenciaSuministroP2Ctrl"].enable();
            this.datosSuministroFormGroup.controls["PotenciaSuministroP4Ctrl"].enable();
            this.datosSuministroFormGroup.controls["PotenciaSuministroP5Ctrl"].enable();
            this.datosSuministroFormGroup.controls["PotenciaSuministroP6Ctrl"].enable();
       }

       this.changeGrupoTarifa(this.data.contrato.tarifa_grupo);
       
   }



   obtenerTarifas()
   {

      this.tarifas = [];

      this.tarifas = [
        {value: "2.0TD", label: "2.0TD"},
        {value: "3.0TD", label: "3.0TD"},
        {value: "6.1TD", label: "6.1TD"},
      ]

   }

   obtenerIdiomas()
   {

      this.idiomas = [];

      this.idiomas = [
        {value: "1", label: "Español"},
        {value: "2", label: "English"},
        {value: "3", label: "Català"},
      ]

   }

   obtenerGruposTarifa()
   {

      this.gruposTarifa = [];

      /*
      this.gruposTarifa = [
          {value: "1", label: 'TARIFA 24H 0122'},
          {value: "2", label: 'TARIFA 24H PRIMER MES 0122'},
          {value: "3", label: 'TARIFA FIJA 0122'},
          {value: "4", label: 'TARIFA FIJA PRIMER MES 0122'},
          {value: "5", label: 'TRANSPARENTE'},
          {value: "6", label: 'TRANSPARENTE PRIMER MES'},
          {value: "7", label: 'INDEXADA 4'},
      ];*/


      if (this.data.contrato.tarifa == "2.0TD")
      {
            this.gruposTarifa = [
                {value: "1", label: 'TARIFA 24H 0122'},
                {value: "2", label: 'TARIFA 24H VAS 0122'},
                {value: "3", label: 'TARIFA FIJA 0122'},
                {value: "4", label: 'TARIFA FIJA VAS 0122'},
                {value: "5", label: 'TRANSPARENTE'},
                {value: "6", label: 'TRANSPARENTE VAS'},
            ];
      }
      else if (this.data.contrato.tarifa == "3.0TD")
      {

        
        this.gruposTarifa = [
            {value: "7", label: 'TARIFA ESTABLE'},
            {value: "8", label: 'TARIFA REGULAR'},
            {value: "9", label: 'INDEXADA 4'},
            {value: "10", label: 'INDEXADA 5'},
            {value: "11", label: 'INDEXADA 6'},
            {value: "12", label: 'INDEXADA 10'},
            {value: "13", label: 'INDEXADA 15'},
            {value: "14", label: 'INDEXADA 20'},
            {value: "15", label: 'INDEXADA 25'},
        ];
      }
      else if (this.data.contrato.tarifa == "6.1TD")
      {

        
        this.gruposTarifa = [
            {value: "7", label: 'TARIFA ESTABLE'},
            {value: "8", label: 'TARIFA REGULAR'},
            {value: "9", label: 'INDEXADA 4'},
            {value: "10", label: 'INDEXADA 5'},
            {value: "11", label: 'INDEXADA 6'},
            {value: "12", label: 'INDEXADA 10'},
            {value: "13", label: 'INDEXADA 15'},
            {value: "14", label: 'INDEXADA 20'},
            {value: "15", label: 'INDEXADA 25'},
        ];
      }

   }

   changeGrupoTarifa(valor)
   {
    
        this.obtenerGruposTarifa();
        //this.datosTarifaFormGroup.controls["GrupoTarifaCtrl"].value(valor);
        this.data.contrato.tarifa_grupo = valor;
   
      
      if (valor == "1")
      {
          this.data.contrato.potencia_P1 = "0.105";
          this.data.contrato.potencia_P2 = "0"; 
          this.data.contrato.potencia_P3 = "0.013";
          this.data.contrato.potencia_P4 = "0"; 
          this.data.contrato.potencia_P5 = "0"; 
          this.data.contrato.potencia_P6 = "0";

          this.data.contrato.energia_P1 = "0.299";
          this.data.contrato.energia_P2 = "0.299";
          this.data.contrato.energia_P3 = "0.299";
          this.data.contrato.energia_P4 = "";
          this.data.contrato.energia_P5 = "";
          this.data.contrato.energia_P6 = "";

          this.data.contrato.tarifa_energia_responsable = 0;
      }
      else if (valor == "2")
      {
          this.data.contrato.potencia_P1 = "0.105";
          this.data.contrato.potencia_P2 = "0"; 
          this.data.contrato.potencia_P3 = "0.013";
          this.data.contrato.potencia_P4 = "0"; 
          this.data.contrato.potencia_P5 = "0"; 
          this.data.contrato.potencia_P6 = "0";

          this.data.contrato.energia_P1 = "0.299";
          this.data.contrato.energia_P2 = "0.299";
          this.data.contrato.energia_P3 = "0.299";
          this.data.contrato.energia_P4 = "";
          this.data.contrato.energia_P5 = "";
          this.data.contrato.energia_P6 = "";

          this.data.contrato.tarifa_energia_responsable = 1;
      }
      else if (valor == "3")
      {
          this.data.contrato.potencia_P1 = "0.105";
          this.data.contrato.potencia_P2 = "0"; 
          this.data.contrato.potencia_P3 = "0.013";
          this.data.contrato.potencia_P4 = "0"; 
          this.data.contrato.potencia_P5 = "0"; 
          this.data.contrato.potencia_P6 = "0";

          this.data.contrato.energia_P1 = "0.374";
          this.data.contrato.energia_P2 = "0.288";
          this.data.contrato.energia_P3 = "0.257";
          this.data.contrato.energia_P4 = "";
          this.data.contrato.energia_P5 = "";
          this.data.contrato.energia_P6 = "";

          this.data.contrato.tarifa_energia_responsable = 0; 
      }
      else if (valor == "4")
      {
          this.data.contrato.potencia_P1 = "0.105";
          this.data.contrato.potencia_P2 = "0"; 
          this.data.contrato.potencia_P3 = "0.013";
          this.data.contrato.potencia_P4 = "0"; 
          this.data.contrato.potencia_P5 = "0"; 
          this.data.contrato.potencia_P6 = "0";

          this.data.contrato.energia_P1 = "0.374";
          this.data.contrato.energia_P2 = "0.288";
          this.data.contrato.energia_P3 = "0.257";
          this.data.contrato.energia_P4 = "";
          this.data.contrato.energia_P5 = "";
          this.data.contrato.energia_P6 = "";

          this.data.contrato.tarifa_energia_responsable = 1; 
      }
      else if (valor == "5")
      {
          this.data.contrato.potencia_P1 = "0.073807";
          this.data.contrato.potencia_P2 = "0"; 
          this.data.contrato.potencia_P3 = "0.007943";
          this.data.contrato.potencia_P4 = "0"; 
          this.data.contrato.potencia_P5 = "0"; 
          this.data.contrato.potencia_P6 = "0";

          this.data.contrato.energia_P1 = "Precio de coste + 0,01 €/kWh";
          this.data.contrato.energia_P2 = "Precio de coste + 0,01 €/kWh";
          this.data.contrato.energia_P3 = "Precio de coste + 0,01 €/kWh";
          this.data.contrato.energia_P4 = "";
          this.data.contrato.energia_P5 = "";
          this.data.contrato.energia_P6 = "";

          this.data.contrato.tarifa_energia_responsable = 0;
      }
      else if (valor == "6")
      {
            this.data.contrato.potencia_P1 = "0.073807";
            this.data.contrato.potencia_P2 = "0"; 
            this.data.contrato.potencia_P3 = "0.007943";
            this.data.contrato.potencia_P4 = "0"; 
            this.data.contrato.potencia_P5 = "0"; 
            this.data.contrato.potencia_P6 = "0";

            this.data.contrato.energia_P1 = "Precio de coste + 0,01 €/kWh";
            this.data.contrato.energia_P2 = "Precio de coste + 0,01 €/kWh";
            this.data.contrato.energia_P3 = "Precio de coste + 0,01 €/kWh";
            this.data.contrato.energia_P4 = "";
            this.data.contrato.energia_P5 = "";
            this.data.contrato.energia_P6 = "";

            this.data.contrato.tarifa_energia_responsable = 1;
      }
      else if (valor == "7")
      {
          this.data.contrato.potencia_P1 = "BOE";
          this.data.contrato.potencia_P2 = "BOE"; 
          this.data.contrato.potencia_P3 = "BOE";
          this.data.contrato.potencia_P4 = "BOE"; 
          this.data.contrato.potencia_P5 = "BOE"; 
          this.data.contrato.potencia_P6 = "BOE";

          this.data.contrato.energia_P1 = "0.2899";
          this.data.contrato.energia_P2 = "0.2899";
          this.data.contrato.energia_P3 = "0.2899";
          this.data.contrato.energia_P4 = "0.2899";
          this.data.contrato.energia_P5 = "0.2899";
          this.data.contrato.energia_P6 = "0.2899";

          this.data.contrato.tarifa_energia_responsable = 0;
      }
      else if (valor == "8")
      {
          this.data.contrato.potencia_P1 = "BOE";
          this.data.contrato.potencia_P2 = "BOE";
          this.data.contrato.potencia_P3 = "BOE";
          this.data.contrato.potencia_P4 = "BOE";
          this.data.contrato.potencia_P5 = "BOE";
          this.data.contrato.potencia_P6 = "BOE";

          this.data.contrato.energia_P1 = "0.4298";
          this.data.contrato.energia_P2 = "0.3758";
          this.data.contrato.energia_P3 = "0.3137";
          this.data.contrato.energia_P4 = "0.2807";
          this.data.contrato.energia_P5 = "0.2448";
          this.data.contrato.energia_P6 = "0.2227";

          this.data.contrato.tarifa_energia_responsable = 0;
      }
      else if (valor == "9")
      {
          this.data.contrato.potencia_P1 = "BOE";
          this.data.contrato.potencia_P2 = "BOE"; 
          this.data.contrato.potencia_P3 = "BOE";
          this.data.contrato.potencia_P4 = "BOE"; 
          this.data.contrato.potencia_P5 = "BOE"; 
          this.data.contrato.potencia_P6 = "BOE";

          this.data.contrato.energia_P1 = "Precio de coste + 0,004 €/kWh";
          this.data.contrato.energia_P2 = "Precio de coste + 0,004 €/kWh";
          this.data.contrato.energia_P3 = "Precio de coste + 0,004 €/kWh";
          this.data.contrato.energia_P4 = "Precio de coste + 0,004 €/kWh";
          this.data.contrato.energia_P5 = "Precio de coste + 0,004 €/kWh";
          this.data.contrato.energia_P6 = "Precio de coste + 0,004 €/kWh";

          this.data.contrato.tarifa_energia_responsable = 0;
      }
      else if (valor == "10")
      {
        this.data.contrato.potencia_P1 = "BOE";
        this.data.contrato.potencia_P2 = "BOE"; 
        this.data.contrato.potencia_P3 = "BOE";
        this.data.contrato.potencia_P4 = "BOE"; 
        this.data.contrato.potencia_P5 = "BOE"; 
        this.data.contrato.potencia_P6 = "BOE";

        this.data.contrato.energia_P1 = "INDEX5";
        this.data.contrato.energia_P2 = "INDEX5";
        this.data.contrato.energia_P3 = "INDEX5";
        this.data.contrato.energia_P4 = "INDEX5";
        this.data.contrato.energia_P5 = "INDEX5";
        this.data.contrato.energia_P6 = "INDEX5";

        this.data.contrato.tarifa_energia_responsable = 0;
      }
      else if (valor == "11")
      {
        this.data.contrato.potencia_P1 = "BOE";
        this.data.contrato.potencia_P2 = "BOE"; 
        this.data.contrato.potencia_P3 = "BOE";
        this.data.contrato.potencia_P4 = "BOE"; 
        this.data.contrato.potencia_P5 = "BOE"; 
        this.data.contrato.potencia_P6 = "BOE";

        this.data.contrato.energia_P1 = "Precio de coste + 0,006€ kWh";
        this.data.contrato.energia_P2 = "Precio de coste + 0,006€ kWh";
        this.data.contrato.energia_P3 = "Precio de coste + 0,006€ kWh";
        this.data.contrato.energia_P4 = "Precio de coste + 0,006€ kWh";
        this.data.contrato.energia_P5 = "Precio de coste + 0,006€ kWh";
        this.data.contrato.energia_P6 = "Precio de coste + 0,006€ kWh";

        this.data.contrato.tarifa_energia_responsable = 0;
      }
      else if (valor == "12")
      {
        this.data.contrato.potencia_P1 = "BOE";
        this.data.contrato.potencia_P2 = "BOE"; 
        this.data.contrato.potencia_P3 = "BOE";
        this.data.contrato.potencia_P4 = "BOE"; 
        this.data.contrato.potencia_P5 = "BOE"; 
        this.data.contrato.potencia_P6 = "BOE";

        this.data.contrato.energia_P1 = "Precio de coste + 0,01 €/kWh";
        this.data.contrato.energia_P2 = "Precio de coste + 0,01 €/kWh";
        this.data.contrato.energia_P3 = "Precio de coste + 0,01 €/kWh";
        this.data.contrato.energia_P4 = "Precio de coste + 0,01 €/kWh";
        this.data.contrato.energia_P5 = "Precio de coste + 0,01 €/kWh";
        this.data.contrato.energia_P6 = "Precio de coste + 0,01 €/kWh";
      }
      else if (valor == "14")//Index20
      {
        this.data.contrato.potencia_P1 = "BOE";
        this.data.contrato.potencia_P2 = "BOE"; 
        this.data.contrato.potencia_P3 = "BOE";
        this.data.contrato.potencia_P4 = "BOE"; 
        this.data.contrato.potencia_P5 = "BOE"; 
        this.data.contrato.potencia_P6 = "BOE";

        this.data.contrato.energia_P1 = "Precio de coste + 0,02 €/kWh";
        this.data.contrato.energia_P2 = "Precio de coste + 0,02 €/kWh";
        this.data.contrato.energia_P3 = "Precio de coste + 0,02 €/kWh";
        this.data.contrato.energia_P4 = "Precio de coste + 0,02 €/kWh";
        this.data.contrato.energia_P5 = "Precio de coste + 0,02 €/kWh";
        this.data.contrato.energia_P6 = "Precio de coste + 0,02 €/kWh";
      }
      else if (valor == "13")//index15
      {
        this.data.contrato.potencia_P1 = "BOE";
        this.data.contrato.potencia_P2 = "BOE"; 
        this.data.contrato.potencia_P3 = "BOE";
        this.data.contrato.potencia_P4 = "BOE"; 
        this.data.contrato.potencia_P5 = "BOE"; 
        this.data.contrato.potencia_P6 = "BOE";

        this.data.contrato.energia_P1 = "Precio de coste + 0,015 €/kWh";
        this.data.contrato.energia_P2 = "Precio de coste + 0,015 €/kWh";
        this.data.contrato.energia_P3 = "Precio de coste + 0,015 €/kWh";
        this.data.contrato.energia_P4 = "Precio de coste + 0,015 €/kWh";
        this.data.contrato.energia_P5 = "Precio de coste + 0,015 €/kWh";
        this.data.contrato.energia_P6 = "Precio de coste + 0,015 €/kWh";
      }
      else if (valor == "15")//index25
      {
        this.data.contrato.potencia_P1 = "BOE";
        this.data.contrato.potencia_P2 = "BOE"; 
        this.data.contrato.potencia_P3 = "BOE";
        this.data.contrato.potencia_P4 = "BOE"; 
        this.data.contrato.potencia_P5 = "BOE"; 
        this.data.contrato.potencia_P6 = "BOE";

        this.data.contrato.energia_P1 = "Precio de coste + 0,025 €/kWh";
        this.data.contrato.energia_P2 = "Precio de coste + 0,025 €/kWh";
        this.data.contrato.energia_P3 = "Precio de coste + 0,025 €/kWh";
        this.data.contrato.energia_P4 = "Precio de coste + 0,025 €/kWh";
        this.data.contrato.energia_P5 = "Precio de coste + 0,025 €/kWh";
        this.data.contrato.energia_P6 = "Precio de coste + 0,025 €/kWh";
      }
      else 
      {
          this.data.contrato.potencia_P1 = "0";
          this.data.contrato.potencia_P2 = "0"; 
          this.data.contrato.potencia_P3 = "0";
          this.data.contrato.potencia_P4 = "0"; 
          this.data.contrato.potencia_P5 = "0"; 
          this.data.contrato.potencia_P6 = "0";

          this.data.contrato.energia_P1 = "";
          this.data.contrato.energia_P2 = "";
          this.data.contrato.energia_P3 = "";
          this.data.contrato.energia_P4 = "";
          this.data.contrato.energia_P5 = "";
          this.data.contrato.energia_P6 = "";

          this.data.contrato.tarifa_energia_responsable = 0;
      }
      

      
   }

   qw()
   {
      //alert(this.data.contrato.especificar_direccion_facturacion);
   }

   evaluaSiEsPermitidoGuardar()
    {

      /*
      if (this.data.sede.NOMBRE.toString().trim().length > 0)
          this.guardarDeshabilitado = false;
      else
        this.guardarDeshabilitado = true;
        */
       this.guardarDeshabilitado = false;
    }


  }
  interface interficieLlaveValor {
    label:string; 
    value: string
  }
  