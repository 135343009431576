

import { Entity, Column, PrimaryGeneratedColumn } from 'typeorm';
import {hash,compare} from 'bcrypt';

@Entity()

export class Usuarios {



    @PrimaryGeneratedColumn()
    ID_USUARIO: number;

    @Column()
    NOMBRE: string;

    @Column()
    CONTRASEÑA: string

    @Column()
    ROL: number


    constructor(_id_usuario:number=0,_nombre:string="",_password:string="",_rol:number=0)
    {
        this.ID_USUARIO = +_id_usuario;
        this.NOMBRE = _nombre;
        this.CONTRASEÑA = _password;
        this.ROL = +_rol;
    }

 

}



