import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';

import { NoPageFoundComponent } from '../pages/no-page-found/no-page-found.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations:[
    NoPageFoundComponent,
    SidebarComponent,
    HeaderComponent
  ],
  exports: [
    NoPageFoundComponent,
    SidebarComponent,
    HeaderComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatDividerModule,
    MatToolbarModule,
    MatExpansionModule,
    MatButtonModule,
    MatCardModule
  ]
})

export class SharedModule { }
