import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http'
import { Observable } from 'rxjs';
import { Http, Headers, Response } from '@angular/http';
import { HttpService } from '../../services/http/http.service';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(public http:HttpService) { }

  ngOnInit() {

    //console.log("RESPOSTA HOME" + JSON.stringify(this.http.obtenerProfile()));

    this.http.obtenerProfile().then(
      (result) => {
        console.log("RESPOSTA HOME" + JSON.stringify(result));
      }
    )

  }


}
