

import { PrimaryGeneratedColumn, Column, PrimaryColumn, Entity } from 'typeorm';

@Entity('contratos')
export class contratos
{
    @PrimaryColumn() id_contrato:number=0;
    @Column() numeracion:string="";
    @Column() CUPS:string;
    @Column() primera_residencia:number=0;
    @Column() direccion:string="";
    @Column() numero:string="";
    @Column() aclarador:string="";
    @Column() codigo_postal:string="";
    @Column() ciudad:string="";
    @Column() provincia:string="";
    @Column() tarifa:string="";
    @Column() potencia_P1:string="";
    @Column() potencia_P2:string="";
    @Column() potencia_P3:string="";
    @Column() potencia_P4:string="";
    @Column() potencia_P5:string="";
    @Column() potencia_P6:string="";
    @Column() energia_P1:string="";
    @Column() energia_P2:string="";
    @Column() energia_P3:string="";
    @Column() energia_P4:string="";
    @Column() energia_P5:string="";
    @Column() energia_P6:string="";
    @Column() numero_documento:string="";
    @Column() nombre_titular:string="";
    @Column() apellido1_titular:string="";
    @Column() apellido2_titular:string="";
    @Column() nombre_empresa:string="";
    @Column() CP_titular:string="";
    @Column() ciudad_titular:string="";
    @Column() calle_titular:string="";
    @Column() numero_titular:string="";
    @Column() aclarador_titular:string="";
    @Column() numero_telefono_titular:string="";
    @Column() correo_titular:string="";
    @Column() IBAN_titular:string="";
    @Column() tarifa_grupo:string="";
    @Column() tarifa_energia_responsable:number = 0;
    @Column() texto_tipo_impresion:string="";
    @Column() agente:string="";
    @Column() codigo_agente:string="";
    @Column() tipo_solicitud:string="";
    @Column() codigo_promocional:string="";
    @Column() idioma_preferido:string="";

    @Column() especificar_direccion_facturacion:string="N";
    @Column() direccion_facturacion:string="";
    @Column() numero_facturacion:string="";
    @Column() aclarador_facturacion:string="";
    @Column() codigo_postal_facturacion:string="";
    @Column() ciudad_facturacion:string="";    
    @Column() provincia_facturacion:string="";

    @Column() potencia_suministro:string="0";
    @Column() potencia_suministro_p2:string="0";
    @Column() potencia_suministro_p3:string="0";
    @Column() potencia_suministro_p4:string="0";
    @Column() potencia_suministro_p5:string="0";
    @Column() potencia_suministro_p6:string="0";

    @Column() codigo_cnae:string="";
    @Column() cambios:string="";
    @Column() envio_contrato:string="";
    @Column() autoconsumo:string="";

    @Column() fecha_formalizacion_contrato:string="";
    
   
    constructor(
        _id_contrato:number=0,
        _numeracion:string="",
        _primera_residencia:number=0,
        _CUPS:string="",
        _direccion:string="",
        _numero:string="",
        _aclarador:string="",
        _codigoPostal:string="",
        _ciudad:string="",
        _provincia:string="",
        _tarifa:string="",
        _potenciaP1:string="",
        _potenciaP2:string="",
        _potenciaP3:string="",
        _potenciaP4:string="",
        _potenciaP5:string="",
        _potenciaP6:string="",
        _energiaP1:string="",
        _energiaP2:string="",
        _energiaP3:string="",
        _energiaP4:string="",
        _energiaP5:string="",
        _energiaP6:string="",
        _numeroDocumento:string="",
        _nombreTitular:string="",
        _apellido1Titular:string="",
        _apellido2Titular:string="",
        _nombreEmpresa:string="",
        _CPTitular:string="",
        _ciudadTitular:string="",
        _calleTitular:string="",
        _numeroTitular:string="",
        _aclaradorTitular:string="",
        _numeroTelefonoTitular:string="",
        _correoTitular:string="",
        _IBANTitular:string="",
        _tarifaGrupo:string="",
        _tarifaEnergiaResponsable:number=0,
        _textoTipoImpresion:string="",
        _agente:string="",
        _codigoAgente:string="",
        _tipoSolicitud:string="",
        _codigoPromocional:string="",
        _idiomaPreferido:string="",
        _direccionFacturacion:string="",
        _numeroFacturacion:string="",
        _aclaradorFacturacion:string="",
        _codigoPostalFacturacion:string="",
        _ciudadFacturacion:string="",
        _especificarDireccionFacturacion:string="",
        _provinciaFacturacion:string="",
        _potenciaSuministro:string="0",
        _potenciaSuministroP2:string="0",
        _potenciaSuministroP3:string="0",
        _potenciaSuministroP4:string="0",
        _potenciaSuministroP5:string="0",
        _potenciaSuministroP6:string="0",
        _codigoCNAE:string="",
        _cambios:string="",
        _envioContrato:string="",
        _autoconsumo:string="",
        _fecha_formalizacion_contrato:string=""

    )
    {
        this.id_contrato = _id_contrato;
        this.numeracion = _numeracion;
        this.CUPS = _CUPS;
        this.primera_residencia = _primera_residencia;
        this.direccion = _direccion;
        this.numero = _numero;
        this.aclarador = _aclarador;
        this.codigo_postal = _codigoPostal;
        this.ciudad = _ciudad;
        this.provincia = _provincia;
        this.tarifa = _tarifa;
        this.potencia_P1 = _potenciaP1;
        this.potencia_P2 = _potenciaP2;
        this.potencia_P3 = _potenciaP3;
        this.potencia_P4 = _potenciaP4;
        this.potencia_P5 = _potenciaP5;
        this.potencia_P6 = _potenciaP6;
        this.energia_P1 = _energiaP1;        
        this.energia_P2 = _energiaP2;
        this.energia_P3 = _energiaP3;
        this.energia_P4 = _energiaP4;
        this.energia_P5 = _energiaP5;
        this.energia_P6 = _energiaP6;
        this.numero_documento = _numeroDocumento;
        this.nombre_titular = _nombreTitular;
        this.apellido1_titular = _apellido1Titular;
        this.apellido2_titular = _apellido2Titular;
        this.nombre_empresa = _nombreEmpresa;
        this.CP_titular = _CPTitular;
        this.ciudad_titular = _ciudadTitular;
        this.calle_titular = _calleTitular;
        this.numero_titular = _numeroTitular;
        this.aclarador_titular = _aclaradorTitular; 
        this.numero_telefono_titular = _numeroTelefonoTitular; 
        this.correo_titular = _correoTitular; 
        this.IBAN_titular = _IBANTitular; 
        this.tarifa_grupo = _tarifaGrupo; 
        this.tarifa_energia_responsable = _tarifaEnergiaResponsable;
        this.texto_tipo_impresion = _textoTipoImpresion;
        this.agente = _agente; 
        this.codigo_agente = _codigoAgente;
        this.tipo_solicitud = _tipoSolicitud;
        this.codigo_promocional = _codigoPromocional;
        this.idioma_preferido = _idiomaPreferido;
        this.direccion_facturacion = _direccionFacturacion;
        this.numero_facturacion = _numeroFacturacion;
        this.aclarador_facturacion = _aclaradorFacturacion;
        this.codigo_postal_facturacion = _codigoPostalFacturacion;
        this.ciudad_facturacion = _ciudadFacturacion;
        this.especificar_direccion_facturacion = _especificarDireccionFacturacion;
        this.provincia_facturacion = _provinciaFacturacion;
        this.potencia_suministro = _potenciaSuministro;
        this.potencia_suministro_p2 = _potenciaSuministroP2;
        this.potencia_suministro_p3 = _potenciaSuministroP3;
        this.potencia_suministro_p4 = _potenciaSuministroP4;
        this.potencia_suministro_p5 = _potenciaSuministroP5;
        this.potencia_suministro_p6 = _potenciaSuministroP6;
        this.codigo_cnae = _codigoCNAE;
        this.cambios = _cambios;
        this.envio_contrato = _envioContrato;
        this.autoconsumo = _autoconsumo;
        this.fecha_formalizacion_contrato = _fecha_formalizacion_contrato;
    
    }

    
    
}