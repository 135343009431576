import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { PagesComponent } from './pages.component';
import { PAGES_ROUTES } from './pages.routes';

import { HomeComponent } from './home/home.component';


import { MatCardModule, MatCardTitle } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ContratosComponent } from './contratos/contratos.component';
import { INV_CreacionEdicionContrato } from './contratos/INV_creacion-edicion-contrato.component';

import { UsuariosComponent } from './usuarios/usuarios.component';
import {CreacionEdicionUsuario } from './usuarios/creacion-edicion-usuario.component';

/*
import { BreadcrumbComponent } from '../shared/breadcrumb/breadcrumb.component';
import { ClientesComponent } from './clientes/clientes.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import {AppRoutingModule} from './pages.routes';
*/
import { AngularIbanModule } from 'angular-iban';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {  MatTableModule,
  MatPaginatorModule,
  MatFormFieldModule,
  MatInputModule,
  MatSortModule,
  MatIconModule,
  MatButtonModule,
  MatDialogModule,
  MatNativeDateModule,
  MatCheckboxModule,
  MatSelectModule,
  MatRadioModule,
  MatDatepickerModule,
  MatTooltipModule,
  MatTabsModule,
  MatChipsModule,
  MatRippleModule,

  MatGridListModule,
  MatProgressSpinnerModule,
  MatAutocompleteModule,
  MAT_DATE_LOCALE,
  MatSlideToggleModule,
  MatStepperModule} from '@angular/material';
  import {MatListModule} from '@angular/material/list';

//import { ComponentsModule } from '../components/components.module';
//import { LoadingOverlayComponent } from './../loading-overlay/loading-overlay.component';
//import { NgxProgressModule  } from '@kken94/ngx-progress';

//GRIDS
import { GuiGridModule } from '@generic-ui/ngx-grid';
import { AngularSlickgridModule } from 'angular-slickgrid';


import { DatePipe } from '@angular/common';
import {BreadcrumbModule} from 'angular-crumbs';
import { NgxPrintModule } from 'ngx-print';
@NgModule({
  declarations:[
    PagesComponent,
    HomeComponent,        
    ContratosComponent,    
    INV_CreacionEdicionContrato,
    UsuariosComponent,
    CreacionEdicionUsuario
    //BreadcrumbComponent
  ],
  exports: [
    HomeComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
   // ComponentsModule,
    PAGES_ROUTES,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatIconModule,
    MatDividerModule,
    MatToolbarModule,
    MatExpansionModule,
    MatButtonModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    MatIconModule,
    MatNativeDateModule,
    MatButtonModule,
    MatTooltipModule,
    MatTabsModule,
    MatGridListModule,
    MatDialogModule,
    MatListModule,
    MatSelectModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    BreadcrumbModule,
    MatDatepickerModule,
    MatRadioModule,
    MatRippleModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    NgxPrintModule,
    GuiGridModule,
    GuiGridModule,    
    MatStepperModule,
    AngularIbanModule,
    AngularSlickgridModule.forRoot({
      // add any Global Grid Options/Config you might want
      // to avoid passing the same options over and over in each grids of your App
      enableAutoResize: true,
      autoResize: {
        containerId: 'demo-container',
        sidePadding: 10
      }
    })
    //BreadcrumbModule,
    //AppRoutingModule
    //NgxProgressModule


  ],
  entryComponents: [
    INV_CreacionEdicionContrato,
    CreacionEdicionUsuario
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
    DatePipe
  ]


})

export class PagesModule { }
