import { Component, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SidebarService } from 'src/app/services/service.index';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls:['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  screenWidth;

  constructor(public _sidebar: SidebarService,public router: Router) { }

  ngOnInit() {
  }

  signOut()
  {
      localStorage.removeItem('token');
      localStorage.removeItem('loggedUsername');
      this.router.navigate(['login']);
  }

  


}
