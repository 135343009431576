import { Injectable } from '@angular/core';

import { contratos } from '@shared/contrato.entity';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { BehaviorSubject, Observable } from 'rxjs';
import { resolve } from 'url';
import { SharedService } from '../shared/shared.service';



@Injectable({
  providedIn: 'root'
})
export class HttpService {

  
  constructor(public http:HttpClient,public shared:SharedService) { }

  
  async obtenerProfile()
  {
      return new Promise((resolve) => {

          this.http.get(this.shared.urlWebServices + 'profile')
          .subscribe((respProfile:any) => {
            //console.log("RESPOSTA HOME" + JSON.stringify(respProfile));
              resolve(respProfile);
          });
      });
  }

  async obtenerNumeracionCorrelativaContrato()
  {
    return new Promise((resolve) => {

        this.http.post(this.shared.urlWebServices + 'contratos/getNumeracionCorrelativa',{})
          .subscribe((respProfile:any) => {
            //console.log("RESPOSTA HOME" + JSON.stringify(respProfile));
              resolve(respProfile);
          });
      });
  }

  async obtenerContratos()
  {

        return new Promise((resolve) => {

          this.http.post(this.shared.urlWebServices + 'contratos/index',{})
            .subscribe((respProfile:any) => {
              //console.log("RESPOSTA HOME" + JSON.stringify(respProfile));
                resolve(respProfile);
            });
        });

  }

  async eliminarContrato(_id_contrato)
  {
      return new Promise((resolve) => {
        this.http.post(this.shared.urlWebServices + 'contratos/delete',
        {
            id_contrato:_id_contrato
        })
        .subscribe((resp:any) => {
            resolve(resp);
        })
    });
  }

  async crearContrato(_contrato:contratos)
  {

    
     return new Promise((resolve) => {
         this.http.post(this.shared.urlWebServices + 'contratos/create',
         {
             cups:_contrato.CUPS,
             numeracion: _contrato.numeracion,
             primera_residencia:_contrato.primera_residencia,
             direccion:_contrato.direccion,
             numero:_contrato.numero,
             aclarador:_contrato.aclarador,
             codigo_postal:_contrato.codigo_postal,
             ciudad:_contrato.ciudad,
             provincia:_contrato.provincia,
             tarifa:_contrato.tarifa,
             potencia_p1:_contrato.potencia_P1,
             potencia_p2:_contrato.potencia_P2,
             potencia_p3:_contrato.potencia_P3,
             potencia_p4:_contrato.potencia_P4,
             potencia_p5:_contrato.potencia_P5,
             potencia_p6:_contrato.potencia_P6,
             numero_documento:_contrato.numero_documento,
             nombre_titular:_contrato.nombre_titular,
             apellido1_titular:_contrato.apellido1_titular,
             apellido2_titular:_contrato.apellido2_titular,
             nombre_empresa:_contrato.nombre_empresa,
             cp_titular:_contrato.CP_titular,
             ciudad_titular:_contrato.ciudad_titular,
             calle_titular:_contrato.calle_titular,
             numero_titular:_contrato.numero_titular,
             aclarador_titular:_contrato.aclarador_titular,
             numero_telefono_titular:_contrato.numero_telefono_titular,
             correo_titular:_contrato.correo_titular,
             IBAN_titular:_contrato.IBAN_titular,
             tarifa_grupo:_contrato.tarifa_grupo,
             tarifa_energia_responsable: _contrato.tarifa_energia_responsable,
             texto_tipo_impresion:_contrato.texto_tipo_impresion,
             agente:_contrato.agente,
             codigo_agente: _contrato.codigo_agente,
             tipo_solicitud:_contrato.tipo_solicitud,
             codigo_promocional:_contrato.codigo_promocional,
             idioma_preferido:_contrato.idioma_preferido,
             energia_p1:_contrato.energia_P1,
             energia_p2:_contrato.energia_P2,
             energia_p3:_contrato.energia_P3,
             energia_p4:_contrato.energia_P4,
             energia_p5:_contrato.energia_P5,
             energia_p6:_contrato.energia_P6,
             especificar_direccion_facturacion: _contrato.especificar_direccion_facturacion,
             direccion_facturacion: _contrato.direccion_facturacion,
             numero_facturacion:_contrato.numero_facturacion,
             aclarador_facturacion:_contrato.aclarador_facturacion,
             codigo_postal_facturacion:_contrato.codigo_postal_facturacion,
             ciudad_facturacion:_contrato.ciudad_facturacion,
             provincia_facturacion:_contrato.provincia_facturacion,
             potencia_suministro:_contrato.potencia_suministro,
             potencia_suministro_p2:_contrato.potencia_suministro_p2,
             potencia_suministro_p3:_contrato.potencia_suministro_p3,
             potencia_suministro_p4:_contrato.potencia_suministro_p4,
             potencia_suministro_p5:_contrato.potencia_suministro_p5,
             potencia_suministro_p6:_contrato.potencia_suministro_p6,
             codigo_cnae:_contrato.codigo_cnae,
             cambios:_contrato.cambios,
             envio_contrato: _contrato.envio_contrato,
             autoconsumo: _contrato.autoconsumo,
             fecha_formalizacion_contrato:_contrato.fecha_formalizacion_contrato

         })
         .subscribe((resp:any) => {
             resolve(resp);
         })
     });

  }

  async obtenerContratoById(_id_contrato:number)
  {

     return new Promise((resolve) => {
         this.http.post(this.shared.urlWebServices + 'contratos/getById',
         {
            id_contrato:_id_contrato

         })
         .subscribe((resp:any) => {
             resolve(resp);
         })
     });

  }

  async actualizar_contrato(_contrato:contratos)
  {

      
     return new Promise((resolve) => {
         this.http.post(this.shared.urlWebServices + 'contratos/update',
         {
             id_contrato:_contrato.id_contrato,
             numeracion : _contrato.numeracion,
             cups:_contrato.CUPS,
             primera_residencia:_contrato.primera_residencia,
             direccion:_contrato.direccion,
             numero:_contrato.numero,
             aclarador:_contrato.aclarador,
             codigo_postal:_contrato.codigo_postal,
             ciudad:_contrato.ciudad,
             provincia:_contrato.provincia,
             tarifa:_contrato.tarifa,
             potencia_p1:_contrato.potencia_P1,
             potencia_p2:_contrato.potencia_P2,
             potencia_p3:_contrato.potencia_P3,
             potencia_p4:_contrato.potencia_P4,
             potencia_p5:_contrato.potencia_P5,
             potencia_p6:_contrato.potencia_P6,
             numero_documento:_contrato.numero_documento,
             nombre_titular:_contrato.nombre_titular,
             apellido1_titular:_contrato.apellido1_titular,
             apellido2_titular:_contrato.apellido2_titular,
             nombre_empresa:_contrato.nombre_empresa,
             cp_titular:_contrato.CP_titular,
             ciudad_titular:_contrato.ciudad_titular,
             calle_titular:_contrato.calle_titular,
             numero_titular:_contrato.numero_titular,
             aclarador_titular:_contrato.aclarador_titular,
             numero_telefono_titular:_contrato.numero_telefono_titular,
             correo_titular:_contrato.correo_titular,
             IBAN_titular:_contrato.IBAN_titular,
             tarifa_grupo:_contrato.tarifa_grupo,
             tarifa_energia_responsable: _contrato.tarifa_energia_responsable,
             texto_tipo_impresion:_contrato.texto_tipo_impresion,
             agente:_contrato.agente,
             codigo_agente: _contrato.codigo_agente,
             tipo_solicitud:_contrato.tipo_solicitud,
             codigo_promocional:_contrato.codigo_promocional,
             idioma_preferido:_contrato.idioma_preferido,
             energia_p1:_contrato.energia_P1,
             energia_p2:_contrato.energia_P2,
             energia_p3:_contrato.energia_P3,
             energia_p4:_contrato.energia_P4,
             energia_p5:_contrato.energia_P5,
             energia_p6:_contrato.energia_P6,
             especificar_direccion_facturacion: _contrato.especificar_direccion_facturacion,
             direccion_facturacion: _contrato.direccion_facturacion,
             numero_facturacion:_contrato.numero_facturacion,
             aclarador_facturacion:_contrato.aclarador_facturacion,
             codigo_postal_facturacion:_contrato.codigo_postal_facturacion,
             ciudad_facturacion:_contrato.ciudad_facturacion,
             provincia_facturacion:_contrato.provincia_facturacion,
             potencia_suministro:_contrato.potencia_suministro,
             potencia_suministro_p2:_contrato.potencia_suministro_p2,
             potencia_suministro_p3:_contrato.potencia_suministro_p3,
             potencia_suministro_p4:_contrato.potencia_suministro_p4,
             potencia_suministro_p5:_contrato.potencia_suministro_p5,
             potencia_suministro_p6:_contrato.potencia_suministro_p6,
             codigo_cnae:_contrato.codigo_cnae,
             cambios:_contrato.cambios,
             envio_contrato: _contrato.envio_contrato,
             autoconsumo: _contrato.autoconsumo,
             fecha_formalizacion_contrato:_contrato.fecha_formalizacion_contrato

         })
         .subscribe((resp:any) => {
             resolve(resp);
         })
     });

  }



  async getNemonByCUPS(_cups)
  {
        return new Promise((resolve) => {
          this.http.post(this.shared.urlWebServices + 'Nemon/getByCUPS',
          {cups:_cups})
          .subscribe((resp:any) => {
              resolve(resp);
          })
      });
  }

  //end pasar objetos entre pantallas

  async login(username,password)
  {

    return new Promise((resolve) => {


        this.http.post(this.shared.urlWebServices + 'auth/login',
        {username: username,password: password})
        .subscribe((resp: any) => {

          //console.log(JSON.stringify(resp));

          localStorage.removeItem('token');
          localStorage.setItem('token',resp.access_token);

          localStorage.removeItem('loggedUsername');
          localStorage.setItem('loggedUsername',username);

          resolve(true);
        });
    });

  }

    /* *******************************USUARIOS  **********************/

  /**
   *
   * @param _nombre
   * @param _password
   * @param _privilegios
   */
   async crearUsuario(_nombre:string,_password:string,_privilegios:string)
   {
 
    console.log("ACTUALIZAR USUARIO: " + _nombre + " - " + _password + " - " + _privilegios);
     return new Promise((resolve) => {
         this.http.post(this.shared.urlWebServices + 'Usuarios/create',
         {nombre:_nombre,contrasena:_password,rol:_privilegios})
         .subscribe((resp:any) => {
             resolve(resp);
         })
     });
 
   }
 
   /**
    *
    * @param _id_usuario
    */
   async borrarUsuario(_id_usuario)
   {
 
     return new Promise((resolve) => {
         this.http.post(this.shared.urlWebServices + 'usuarios/delete',
         {id_usuario:_id_usuario})
         .subscribe((resp:any) => {
             resolve(resp);
         })
     });
 
   }
 
   /**
    *
    * @param _id_usuario
    * @param _nombre
    * @param _password
    * @param _privilegios
    */
   async actualizarUsuario(_id_usuario:string,_nombre:string,_password:string,_privilegios:string)
   {
 
    
     return new Promise((resolve) => {
         this.http.post(this.shared.urlWebServices + 'Usuarios/update',
         {id_usuario:_id_usuario,nombre:_nombre,contrasena: _password,rol: _privilegios})
         .subscribe((resp:any) => {
             resolve(resp);
         })
     });
 
   }
 
   /**
    *
    */
   async obtenerUsuarios()
   {
 
      console.log(this.shared.urlWebServices + 'Usuarios/index');
     return new Promise((resolve) => {
         this.http.post(this.shared.urlWebServices + 'Usuarios/index',
         {})
         .subscribe((resp:any) => {
             resolve(resp);
         });
     });
 
   }

/* *********************************** HUBSPOT ***************************************/
async obtenerContactos()
{

  return new Promise((resolve) => {
      this.http.get(this.shared.urlWebServices + 'Hubspot/getContacts')
      .subscribe((resp:any) => {
          resolve(resp);
      })
  });

}

async obtenerDatosContactoHubspotByCUPS(_cups,_token)
{

  return new Promise((resolve) => {
    
      this.http.post(this.shared.urlWebServices + 'Hubspot/getContactByCUPS',
      {cups:_cups,token:_token})
      .subscribe((resp:any) => {
          resolve(resp);
      })
  });

}

async obtenerOwnerHubspot(_id_owner)
{
  return new Promise((resolve) => {
    
    this.http.post(this.shared.urlWebServices + 'Hubspot/getOwner',
    {owner:_id_owner})
    .subscribe((resp:any) => {
        resolve(resp);
    })
});
}

async obtenerDetallesContacto(_id)
{

  return new Promise((resolve) => {
    
      this.http.post(this.shared.urlWebServices + 'Hubspot/getMoreInfoContact',
      {id:_id})
      .subscribe((resp:any) => {
          resolve(resp);
      })
  });

}

async obtenerOwnerContacto(_id)
{

  return new Promise((resolve) => {
      this.http.post(this.shared.urlWebServices + 'Hubspot/getOwner',
      {owner:_id})
      .subscribe((resp:any) => {
          resolve(resp);
      })
  });

}


}

