import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './pages.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { ContratosComponent } from './contratos/contratos.component';
import { UsuariosComponent } from './usuarios/usuarios.component';

const pagesRoutes: Routes = [
  {
    path: '',
    /*data: {
      breadcrumb: 'Home'
    },
    */
    component: PagesComponent,
    children: [            
      {
        path: 'contratos', 
        component: ContratosComponent,
        /*data: {
          breadcrumb: 'Inicio'
        },*/          
      },                 
      {
        path: '',
        redirectTo: '/contratos', 
        pathMatch: 'full',
      },
      {
        path: 'usuarios',
        component: UsuariosComponent,            
      },
    ]
  },   
];

export const PAGES_ROUTES = RouterModule.forChild( pagesRoutes );



