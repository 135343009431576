import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  menu: any = [   
    {
      titulo: 'Contratos',
      icono: 'fa fa-address-book',
      url: '/contratos'
    },
    {
      titulo: 'Usuarios',
      icono: 'fas fa-users',
      url: '/usuarios'
    }/*
    {
      titulo: 'Projects',
      icono: 'fa fa-check',
      url: '/projects'
    }*/
  ];

  constructor() { }
}
