
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Usuarios } from '@shared/usuarios.entity';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http/http.service';

import { MatTableDataSource, MatPaginator, MatSort, MatSnackBar } from '@angular/material';
import {animate, state, style, transition, trigger} from '@angular/animations';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CreacionEdicionUsuario } from './creacion-edicion-usuario.component';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { jsonpFactory } from '@angular/http/src/http_module';



@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class UsuariosComponent implements OnInit {

 

  /* fins aqui */
  tituloModal:string = "";

  public usuarios = Array<Usuarios>();

  filtrado = new Array<Usuarios>();

  displayedColumns: string[] = ['CODIGO', 'NOMBRE','ROL','editarUsuario'];
  expandedElement: Usuarios | null;

  dataSource =  new MatTableDataSource(this.filtrado);

  @ViewChild(MatPaginator,{static: false}) paginator: MatPaginator;
  @ViewChild(MatSort,{static: false}) sort: MatSort;


  mergeOption: any;

  constructor(
    public router: Router,
    public http:HttpService,
    private spinner: NgxSpinnerService,
    public dddialog: MatDialog,
    private notifyService : NotificationService,
    ) { }


  abrirDialog(_edicion=false,usuario:Usuarios): void {

    var usr = new Usuarios(0,"","",0);

    var _tituloModal = "";
    if(_edicion)
    {
      this.tituloModal = "Editar usuario";
      usr = usuario;
      usr.CONTRASEÑA = ""; //no mostrem password
    }
    else
      this.tituloModal = "Nuevo usuario";


    const dialogRef = this.dddialog.open(CreacionEdicionUsuario, {
      width: '50%',
        data: {tituloModal: this.tituloModal,edicion: _edicion,usuario:usr}
    });


     dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      if (result)
      {

        var privilegisUsuari=0;
        if (result.usuario.ROL)
          privilegisUsuari=1;

        if (result.edicion) //UPDATE
        {
            //console.log("EVALUAR OBJETO");
            //console.log(JSON.stringify(result));
            this.spinner.show();

            
            this.http.actualizarUsuario(result.usuario.ID_USUARIO,result.usuario.NOMBRE,result.usuario.CONTRASENA,privilegisUsuari.toString()).then(
              (resultat) => {
                //console.log("Usuario actualizado correctamente" + JSON.stringify(resultat));
                this.notifyService.showMessage("Usuario actualizado correctamente", "Success",false);
                this.inicializaListadoUsuarios();
                this.spinner.hide();
              })

        }
        else //CREATE
        {
          //console.log(JSON.stringify(result));
          
          this.spinner.show();

          
          this.http.crearUsuario(result.usuario.NOMBRE,result.usuario.CONTRASENA,privilegisUsuari.toString()).then(
            (resultat) => {
              //console.log("Usuario creado correctamente" + JSON.stringify(resultat));
              this.notifyService.showMessage("Usuario creado correctamente", "Success",false);
              this.inicializaListadoUsuarios();
              this.spinner.hide();
            })

        }
      }

    });

  }

  eliminarUsuario(id_usuario)
  {
    if(confirm("Estás seguro? Esta acción no se puede deshacer."))
    {
        this.spinner.show();
        this.http.borrarUsuario(id_usuario).then(
          (resultat) => {
            this.spinner.hide();
            //console.log("Usuario borrado correctamente" + JSON.stringify(resultat));
            this.notifyService.showMessage("Usuario eliminado correctamente", "Success",false);
            this.inicializaListadoUsuarios();
          }),(err) => {
            console.log("ERR eliminando usuario " + JSON.stringify(err));
            this.spinner.hide();
          };

    }
  }

  async ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.inicializaListadoUsuarios();
  }

  filtrar(valor) {

    this.filtrado = this.usuarios.filter(usuario => (usuario.NOMBRE.toString().includes(valor)));

    this.dataSource = new MatTableDataSource(this.filtrado);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }

  async inicializaListadoUsuarios()
  {            
      await this.obtenerUsuarios().then(
        () => {
          this.spinner.hide();
          this.filtrar('');
        }
      );
  }

  async obtenerUsuarios(){

    this.spinner.show();
    await this.http.obtenerUsuarios().then(
      (result:Array<Usuarios>) => {
        if (result)
        {
          console.log("RESULT USUARIOS ---> " + JSON.stringify(result));
            this.usuarios = result;
            /*console.log("RESULTAT ")       ;
            result.forEach(element => {
              //console.log(element);
            });*/

        }
        else 
          console.log("NOT RESULT USUARIOS");

      }
    )

}

}

