import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';

import { MatCardModule, MatCardTitle } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {  MatTableModule,
  MatPaginatorModule,
  MatInputModule,
  MatSortModule,
  MatIconModule,
  MatButtonModule,
  MatDialogModule,
  MatNativeDateModule,
  MatCheckboxModule,
  MatSelectModule,
  MatRadioModule,
  MatDatepickerModule,
  MatTooltipModule,
  MatTabsModule,
  MatChipsModule,
  MatRippleModule,
  MatGridListModule,
  MatProgressSpinnerModule,
  MatAutocompleteModule,
  MAT_DATE_LOCALE,
  MatSlideToggleModule, 
  MatFormFieldModule} from '@angular/material';
  import {MatListModule} from '@angular/material/list';
@NgModule({
  declarations:[
  ],
  exports: [
    MatFormFieldModule,
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatIconModule,
    MatDividerModule,
    MatToolbarModule,
    MatExpansionModule,
    MatButtonModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatInputModule,
    MatSortModule,
    MatIconModule,
    MatNativeDateModule,
    MatButtonModule,
    MatTooltipModule,
    MatTabsModule,
    MatGridListModule,
    MatDialogModule,
    MatListModule,
    MatSelectModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatRadioModule,
    MatRippleModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatSlideToggleModule
  ],
  imports: [
    
    //BreadcrumbModule,
    //AppRoutingModule
    //NgxProgressModule


  ],
  
  entryComponents: [

  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'}
  ]


})

export class ComponentsModule { }
