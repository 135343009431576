import {RouterModule, Routes} from '@angular/router';

import { LoginComponent } from './login/login.component';
import { ContratosComponent } from './pages/contratos/contratos.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { NoPageFoundComponent } from './pages/no-page-found/no-page-found.component';



const appRoutes: Routes = [

  {path: 'login', component: LoginComponent},
  //{path: '**', component: NoPageFoundComponent},
  {path: 'contratos', component: ContratosComponent},
  {path: 'usuarios', component: UsuariosComponent}
];

export const APP_ROUTES = RouterModule.forRoot( appRoutes, {useHash: true} );
