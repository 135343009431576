import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';

import { AppComponent } from './app.component';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Rutas
import { APP_ROUTES } from './app.routes';

// Modulos
import { PagesModule } from './pages/pages.module';

// Servicios
import { ServiceModule } from './services/service.module';

// Temporal
import { FormsModule } from '@angular/forms';

//GRIDS
import { GuiGridModule } from '@generic-ui/ngx-grid';
import { AngularSlickgridModule } from 'angular-slickgrid';

//HTTP

// Interceptors
import { AuthInterceptorService } from './services/auth/auth-interceptor.service';



//import { MatSpinnerOverlayComponent } from './mat-spinner-overlay/mat-spinner-overlay.component';

//import { UsuariosIndexComponent } from './pages/usuarios/index/index.component';

//import { HTTP_INTERCEPTORS } from '@angular/common/http';
//import { TokenInterceptor } from './services/auth/token.interceptor';

import { ToastrModule } from 'ngx-toastr';
// import { NgxProgressModule } from '@kken94/ngx-progress';
import { NgxSpinnerModule } from "ngx-spinner";
import { ComponentsModule } from './components/components.module';


// @dynamic
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
  
  
             
    //MatSpinnerOverlayComponent
    
   // UsuariosIndexComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
        APP_ROUTES,
    PagesModule,
    FormsModule,
    ServiceModule,
    BrowserAnimationsModule, 
    ToastrModule.forRoot(),
    // NgxProgressModule,
    NgxSpinnerModule,
    ComponentsModule,
    GuiGridModule,
    AngularSlickgridModule.forRoot({
      // add any Global Grid Options/Config you might want
      // to avoid passing the same options over and over in each grids of your App
      enableAutoResize: true,
      autoResize: {
        containerId: 'demo-container',
        sidePadding: 10
      }
    })
    
    
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
