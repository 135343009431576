import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http'
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Http, Headers, Response } from '@angular/http';
import { HttpService} from '../services/http/http.service';
//import { UserEntity } from '@shared/user.entity'
//import { NotificationService } from '../services/notification/notification.service'
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public username:string = "";
  public password:string = "";
  //http: HttpClient;
  fact$: Observable<string>;
  constructor( public router: Router,public http:HttpService,private spinner: NgxSpinnerService) {
 
       

   }

  ngOnInit() {
  

  }  

  

  ingresar(){
  
      this.spinner.show();
      this.http.login(this.username,this.password).then(
        (result) => {
          if (result)        
          {
              
              this.spinner.hide();
              this.router.navigate(['contratos']);             
          }
            
        }
      )

  }
  
}
