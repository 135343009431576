import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  public apiKeyHubspot = "9a4af9b2-5d72-43bb-af3c-ed85f6735525";
  // public urlWebServices = "http://localhost:4000/api/";
  public urlWebServices = "https://escandinava.emas.es/api/";
  constructor() { }
}
