import { Component, OnInit, ViewChild,Renderer2, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute, Route, NavigationStart, NavigationEnd, Params } from '@angular/router';
import { HttpService } from 'src/app/services/http/http.service';

import { MatTableDataSource, MatPaginator, MatSort, MatSnackBar, MatDialog } from '@angular/material';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { NotificationService } from 'src/app/services/notification/notification.service';
//import { INV_CreacionEdicionCliente } from './INV_creacion-edicion-cliente.component';

import {BreadcrumbComponent, Breadcrumb, BreadcrumbService,BreadcrumbModule} from 'angular-crumbs';

/*import * as moment from 'moment';*/
import { ConvertActionBindingResult } from '@angular/compiler/src/compiler_util/expression_converter';
import Contacto  from './contacto';
import { DatePipe } from '@angular/common';
import { INV_CreacionEdicionContrato } from './INV_creacion-edicion-contrato.component';
import { contratos } from '@shared/contrato.entity';
import {
  AngularGridInstance,
  Column,
  ExtensionName,
  FieldType,
  Filters,
  Formatter,
  Formatters,
  GridOption,
  unsubscribeAllObservables,
  SlickGrid,
  SlickgridConfig,
} from "angular-slickgrid";

import { ExcelService } from './../../services/shared/excel.service';

import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import * as FileSaver from 'file-saver';
import { Alert } from 'selenium-webdriver';

import * as moment from 'moment';

@Component({
  selector: 'contratos',
  templateUrl: './contratos.component.html',
  styleUrls: ['./contratos.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ContratosComponent implements OnInit {



  rowData = [];
  filtrado = new Array<any>();

  angularGrid: AngularGridInstance;
  contratos = Array<any>();
  pageReady = false;
  tituloModal = "";
  filtradoGrd = "";
  edicion = false;
  source: Array<any> = [];
  aggregated : any;
  
  displayedColumns: string[] = ['NOMBRE','editarGrupo'];
  
  columnDefinitions1: Column[];
  columnDefinitionsHiddenDefault: Column[];
  dataset1: any[] = [];
  gridOptions1: GridOption;


  @ViewChild(MatPaginator,{static: false}) paginator: MatPaginator;
  @ViewChild(MatSort,{static: false}) sort: MatSort;


  constructor(
    public router: Router,
    public http:HttpService,
    private spinner: NgxSpinnerService,
    private renderer2: Renderer2,
    private notifyService : NotificationService,
    public dialog: MatDialog,
    private breadcrumbService: BreadcrumbService, private route: ActivatedRoute,
    private datePipe: DatePipe,
    private excelService:ExcelService) 
    { 

      
          const actionFormatter: Formatter = (
            row,
            cell,
            value,
            columnDef,
            dataContext
          ) => {
            return `<div class="fake-hyperlink">Acciones <i class="fa fa-caret-down"></i></div>`;
          };

          
          /*const importeFormatter: Formatter = (
            row,
            cell,
            value,
            columnDef,
            dataContext
          ) => {
            
            return value + " " + dataContext["MONEDA_INVERSION"];
          };
          */
          

          this.columnDefinitions1 = [
            
            {
              id: "CUPS",
              name: "CUPS",
              field: "CUPS",
              //width: 150,
              sortable: true,
              filterable: true,
              type: FieldType.string,
              filter: {
                enableTrimWhiteSpace: true,
              },
            }, 
            {
              id: "nombre",
              name: "Nombre",
              field: "nombre",
              //width: 150,
              sortable: true,
              filterable: true,
              type: FieldType.string,
              filter: {
                enableTrimWhiteSpace: true,
              },
            },     
            {
              id: "email",
              name: "Email",
              field: "email",
              //width: 150,
              sortable: true,
              filterable: true,
              type: FieldType.string,
              filter: {
                enableTrimWhiteSpace: true,
              },
            },
            {
              id: "telefono",
              name: "Telefono",
              field: "telefono",
              //width: 150,
              sortable: true,
              filterable: true,
              type: FieldType.string,
              filter: {
                enableTrimWhiteSpace: true,
              },
            },
            {
              id: "direccion",
              name: "Dirección",
              field: "direccion",
              width: 250,
              sortable: true,
              filterable: true,
              type: FieldType.string,
              filter: {
                enableTrimWhiteSpace: true,
              },
            },
            {
              id: "logalty",
              name: "Logalty",
              field: "logalty",
              width: 150,
              sortable: true,
              filterable: true,
              type: FieldType.string,
              filter: {
                enableTrimWhiteSpace: true,
              },
            },
            {
              id: "sige",
              name: "Sige",
              field: "sige",
              width: 150,
              sortable: true,
              filterable: true,
              type: FieldType.string,
              filter: {
                enableTrimWhiteSpace: true,
              },
            },     
            {
              id: "acciones",
              name: "Acciones",
              field: "acciones",
              columnGroup: "Acciones",
              excludeFromExport: true,
              formatter: actionFormatter,
              cellMenu: {
                hideCloseButton: false,
                width: 200,
                menuUsabilityOverride: (args) => {
                  return true;
                },
                commandItems: [
                  { command: "editar", title: "Editar", positionOrder: 60 },
                  { command: "pdf", title: "Descargar PDF", disabled:false, positionOrder: 61 },
                  { command: "estado", title: "Establecer estado", disabled:true, positionOrder: 62 },
                  { command: "sige", title: "Exportar para SIGE", disabled:false, positionOrder: 63 },                  
                  { command: "logalty", title: "Certificar logalty", disabled:false, positionOrder: 64 },                  
                  { command: "statuslogalty", title: "Verificar Estado Logalty", disabled:false, positionOrder: 65 },                  
                  { command: "downloadlogalty", title: "Descargar Logalty", disabled:true, positionOrder: 6 },                  
                  { divider: true, command: "", positionOrder: 65 },
                  {
                    command: "eliminar",
                    title: "Eliminar",
                    cssClass: "red",
                    positionOrder: 66,
                  },
                ],
              },
            },
          ];

          this.gridOptions1 = {
            columnPicker: {
              hideForceFitButton: true,
              hideSyncResizeButton: true,
              onColumnsChanged: (e, args) => {
                console.log(
                  "Column selection changed from Column Picker, visible columns: ",
                  args.columns
                );
              },
            },
            autoEdit: true,
            createPreHeaderPanel: true,
            showPreHeaderPanel: true,
            preHeaderPanelHeight: 28,
            //enableAutoResize: true,
            forceFitColumns: true,
            enableGridMenu: true,
            autoHeight: true,
            // enable the filtering but hide the user filter row since we use our own single filter
            enableFiltering: true,
            showHeaderRow: true, // hide the filter row (header row)

            alwaysShowVerticalScroll: false,
            enableColumnPicker: true,
            enableRowSelection: true,
            enablePagination: true,
            pagination: {
              pageSizes: [15, 30, 50, 100],
              pageSize: 15,
            },
            enableCellMenu: true,
            /*
                autoResize: {
                  containerId: 'container',
                  sidePadding: 5
                },
                */
          
            enableAutoResize: true,
            enableCellNavigation: true,
            enableCheckboxSelector: true,
            multiSelect: true,
            enableSorting: true,
            excelExportOptions: {
              exportWithFormatter: true,
              customColumnWidth: 15,
              columnHeaderStyle: { font: { bold: true, italic: true } },
            },

            enableContextMenu: true,

            cellMenu: {
              onCommand: (e, args) => this.executeCommand(e, args),
              onOptionSelected: (e, args) => {
                const dataContext = args && args.dataContext;
                if (dataContext && dataContext.hasOwnProperty("completed")) {
                  dataContext.completed = args.item.option;
                  this.angularGrid.gridService.updateItem(dataContext);
                }
              },
              onBeforeMenuClose: (e, args) =>
                console.log("Cell Menu is closing", args),
            },

            
          
            
          };

          this.inicializaListadoContratos();


    }

    executeCommand(e, args) {
      const columnDef = args.column;
      const command = args.command;
      const dataContext = args.dataContext;
  
      
      console.log(JSON.stringify(dataContext));    
      switch (command) {
        case "editar":
          this.editarContrato(dataContext.id);
          break;
        case "eliminar":
          this.eliminarContrato(dataContext.id);
          break;
        case "sige":
          this.exportarCSVParaSige(dataContext.id);
          break;
        case "pdf":
          this.visualizarPDFContrato(dataContext.id);
          break;
        case "logalty"://send pdf to API
            this.enviarLogalty(dataContext.id);
            break;
        case "statuslogalty"://send pdf to API
        this.estadoLogalty(dataContext.id);

            break;
        case "downloadlogalty"://send pdf to API
            //this.visualizarPDFContrato(dataContext.id);
            break;
      }    
            
    }

    exportarCSVParaSige(_idContrato)
    {
      
        this.http.obtenerContratoById(_idContrato).then(
          (contratoObtenido:contratos) => {

            var _direccionIgualASuministro = "";
            var _vincularDireccion="Titular";
            if (contratoObtenido.especificar_direccion_facturacion == "N")
            {
              _direccionIgualASuministro = "Si";
              _vincularDireccion = "Suministro";

              contratoObtenido.codigo_postal_facturacion = "";
              contratoObtenido.ciudad_facturacion = "";
              contratoObtenido.direccion_facturacion = "";
              contratoObtenido.numero_facturacion = "";
              contratoObtenido.aclarador_facturacion = "";
              contratoObtenido.codigo_postal_facturacion = "";
              
            }              

            var _perfilFacturacionContratoTarifa = "ML Indexado Pass Pool";
            if (contratoObtenido.tarifa == "2.0TD")
              _perfilFacturacionContratoTarifa = "Indexado Sin Máximetro";

            var _tipoSolicitud = "MZ";
            var _codigoTipoCambioTitular = "";
              if ((_tipoSolicitud == "MZ") || (_tipoSolicitud == "JZ"))
                _codigoTipoCambioTitular = "S";

            var _codigoREEDistribuidora="";                        
            if(contratoObtenido.CUPS.substring(2,6) == "0031")
            {
                if(contratoObtenido.CUPS.substring(2,8) == "003140")
                  _codigoREEDistribuidora = "0024";
                else if(contratoObtenido.CUPS.substring(2,8) == "003110")
                  _codigoREEDistribuidora = "0023";
                else if(contratoObtenido.CUPS.substring(2,8) == "003130")
                  _codigoREEDistribuidora = "0029";
                else if(contratoObtenido.CUPS.substring(2,8) == "003160")
                  _codigoREEDistribuidora = "0363";
                else if(contratoObtenido.CUPS.substring(2,8) == "003144")
                  _codigoREEDistribuidora = "0396";
                else if(contratoObtenido.CUPS.substring(2,8) == "003150")
                  _codigoREEDistribuidora = "0288";
            }
            else 
              _codigoREEDistribuidora = contratoObtenido.CUPS.substring(2,6);

            var _calendario = "FACTURACION";
            var _codigoProgramacion = "CEEEC01";
            if (contratoObtenido.codigo_postal.toString().trimLeft().trimRight().substring(0,2) == "07")
            {
                _calendario = "BALEARES";
                _codigoProgramacion = "CEEESB";
            }              
            else if ((contratoObtenido.codigo_postal.toString().trimLeft().trimRight().substring(0,2) == "35") || (contratoObtenido.codigo_postal.toString().trimLeft().trimRight().substring(0,2) == "38"))
            {
                _calendario = "CANARIAS";
                _codigoProgramacion = "CEEEGC";
            }
              

            var _tipoSolicitud="";
            if (contratoObtenido.cambios.toString().trimLeft().trimRight().toLowerCase() == "titular")
              _tipoSolicitud = "MZ";
            else if (contratoObtenido.cambios.toString().trimLeft().trimRight().toLowerCase() == "técnicos")
              _tipoSolicitud = "KZ";
            else if (contratoObtenido.cambios.toString().trimLeft().trimRight().toLowerCase() == "no")
              _tipoSolicitud = "CZ";
            else if (contratoObtenido.cambios.toString().trimLeft().trimRight().toLowerCase() == "alta nueva")
              _tipoSolicitud = "AZ";
            else if (contratoObtenido.cambios.toString().trimLeft().trimRight().toLowerCase() == "titular+técnicos")
              _tipoSolicitud = "JZ";

              var _fechaContrato="";
              try {
                _fechaContrato = moment(contratoObtenido.fecha_formalizacion_contrato).add(1,"hour").format('DD/MM/YYYY'); 
              } catch (error) {
                
              }
       


              var data: any = [{
                CodigoCUPS: contratoObtenido.CUPS.replace("0F","").replace("1P","").replace("0P","").replace(/\u200B/g,''),
                CodigoREEDistribuidora: _codigoREEDistribuidora.replace(/\u200B/g,''),
                CallePuntoSuministro: contratoObtenido.direccion.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                Numero:contratoObtenido.numero.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                Aclarador:contratoObtenido.aclarador_titular.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                CodPostal:contratoObtenido.codigo_postal.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                CiudadSuministro:contratoObtenido.ciudad.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                CodigoProvinciaSuministro:contratoObtenido.codigo_postal.toString().trimLeft().trimRight().substring(0,2).replace(/\u200B/g,''),
                ComercializadoraREE:"",
                TextoTarifaAcceso:contratoObtenido.tarifa.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                PerfilFacturacionPeaje:"",
                PotenciaP1:contratoObtenido.potencia_suministro.toString().trimLeft().trimRight().replace(".",",").replace(/\u200B/g,''),
                PotenciaP2:contratoObtenido.potencia_suministro_p2.toString().trimLeft().trimRight().replace(".",",").replace(/\u200B/g,''),
                PotenciaP3:contratoObtenido.potencia_suministro_p3.toString().trimLeft().trimRight().replace(".",",").replace(/\u200B/g,''),
                PotenciaP4:contratoObtenido.potencia_suministro_p4.toString().trimLeft().trimRight().replace(".",",").replace(/\u200B/g,''),
                PotenciaP5:contratoObtenido.potencia_suministro_p5.toString().trimLeft().trimRight().replace(".",",").replace(/\u200B/g,''),
                PotenciaP6:contratoObtenido.potencia_suministro_p6.toString().trimLeft().trimRight().replace(".",",").replace(/\u200B/g,''),
                Identificador:contratoObtenido.numero_documento.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                NombreTitular:contratoObtenido.nombre_titular.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                Apellido1Titular:contratoObtenido.apellido1_titular.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                Apellido2Titular:contratoObtenido.apellido2_titular.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                RazonSocialTitular:contratoObtenido.nombre_empresa.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                DireccionIgualASuministro:_direccionIgualASuministro.replace(/\u200B/g,''),
                CPTitular:contratoObtenido.codigo_postal_facturacion.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                CiudadTitular:contratoObtenido.ciudad_facturacion.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                CalleTitular:contratoObtenido.direccion_facturacion.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                NumeroTitular:contratoObtenido.numero_facturacion.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                AclaradorTitular:contratoObtenido.aclarador_facturacion.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                CodigoProvinciaTitular:contratoObtenido.codigo_postal_facturacion.toString().trimLeft().trimRight().substring(0,2).replace(/\u200B/g,''),
                TfnoTitular:contratoObtenido.numero_telefono_titular.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                EmailTitular:contratoObtenido.correo_titular.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                NombreEnvio:contratoObtenido.nombre_titular.toString().trimLeft().trimRight().replace(/\u200B/g,'') + " " + contratoObtenido.apellido1_titular.toString().trimLeft().trimRight().replace(/\u200B/g,'') + " " + contratoObtenido.apellido2_titular.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                VincularDireccion:_vincularDireccion.replace(/\u200B/g,''),
                CPEnvio:"",
                CiudadEnvio:"",
                CalleEnvio:"",
                NumeroEnvio:"",
                AclaradorEnvio:"",
                CodigoProvinciaEnvio:"",
                NombrePagador:contratoObtenido.nombre_titular.toString().trimLeft().trimRight().replace(/\u200B/g,'') + " " + contratoObtenido.apellido1_titular.toString().trimLeft().trimRight().replace(/\u200B/g,'') + " " + contratoObtenido.apellido2_titular.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                IdentificadorPagador:contratoObtenido.numero_documento.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                TextoTipoCobro:"Recibo Domiciliado a 7 días",
                IBAN:contratoObtenido.IBAN_titular.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                Tarifa:contratoObtenido.tarifa.toString().trimLeft().trimRight().replace(/\u200B/g,'') + " ML",
                TarifaGrupo:this.obtenerGruposTarifa(contratoObtenido.tarifa_grupo.toString().trimLeft().trimRight()).replace(/\u200B/g,''),
                PerfilFacturacionContratoTarifa:_perfilFacturacionContratoTarifa.replace(/\u200B/g,''),
                FechaDesde:"",//VACIO SIEMPRE
                FechaHasta:"",//VACIO SIEMPRE
                ReferenciaExterna:contratoObtenido.numeracion.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                Calendario:_calendario,//En península "FACTURACION", en Canarias "CANARIAS", en Baleares "BALEARES"
                UnidadProgramacion:_codigoProgramacion.replace(/\u200B/g,''),//FORMULA
                TextoPeriodo:"MENSUAL",//siempre
                TextoTipoImpresion:contratoObtenido.texto_tipo_impresion.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                NombreAgente:contratoObtenido.codigo_agente.toString().trimLeft().trimRight().toUpperCase().replace(/\u200B/g,''),
                CodigoVendedor:"",//VACÍO SIEMPRE
                RefCatastral:"",//VACIO SIEMPRE
                RefExterna:"",//VACIO SIEMPRE
                //CodigoCNAE:"9810",//SIEMPRE
                CodigoCNAE:contratoObtenido.codigo_cnae,
                TipoSolicitud:_tipoSolicitud,//DUBTE!!!!!!!!!!
                SolicitudTipoFechaEfecto:"A",//siempre
                Producto:"",//VACIO SIEMPRE
                FechaContrato:_fechaContrato,//DUBTE!!!!!!!
                TextoModeloContrato:"",//VACÍO SIEMPRE
                FechaCore:"",//VACÍO SIEMPRE
                FechaB2B:"",//VACÍO SIEMPRE
                ModeloFactura:"Factura Escandinava",//SIEMPRE
                TipoImpuesto:"",//VACÍO SIEMPRE
                SolicitudMotivoCodificacion:"",//VACÍO SIEMPRE
                Colectivo:"",//VACÍO SIEMPRE??
                CodigoMunicipioSuministro:"",//VACÍO SIEMPRE
                CalendarioCartera:"todos",//SIEMPRE
                TextoMarca:"Escandinava",//SIEMPRE
                NombreRepresentante:this.titleCase(contratoObtenido.agente.toString().trimLeft().trimRight()).replace(/\u200B/g,''),
                CodigoTipoCambioTitular:_codigoTipoCambioTitular,
                NombreAdministrador:"",//VACÍO SIEMPRE
                Segmento:"Escandinava",//SIEMPRE
                DNIRepresentante:"",//VACÍO SIEMPRE
                FechaVencimiento:"",//VACÍO SIEMPRE
                ClavePrecioID:"",//VACÍO SIEMPRE
                CanalCodAge:"T",//SIEMPRE T
                CodigoPromocion:contratoObtenido.codigo_promocional.toString().trimLeft().trimRight().replace(/\u200B/g,'')





                }];

                var today = new Date();
                var dd = String(today. getDate()). padStart(2, '0');
                var mm = String(today. getMonth() + 1). padStart(2, '0'); //January is 0!
                var yyyy = today. getFullYear();

                var nombre = "";
                if (contratoObtenido.nombre_titular.toString().trim() != "")
                    nombre = contratoObtenido.nombre_titular + "_" + contratoObtenido.apellido1_titular + "_" + contratoObtenido.apellido2_titular + "_" + contratoObtenido.CUPS + "_" + dd + mm + yyyy;        
                else 
                    nombre = contratoObtenido.nombre_empresa + "_" +contratoObtenido.CUPS + "_" + dd + mm + yyyy;   

      
              this.excelService.exportAsExcelFile(data, nombre);
          
          }
        )
        

        
    }

    titleCase(str) {
      return str.split(' ').map(item => 
             item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()).join(' ');
    }
    
  obtenerGruposTarifa(_id)
   {

    if (_id == "1")
      return "TARIFA 24H 0122";
    else if (_id == "2")
      return "TARIFA 24H VAS 0122";
    else if (_id == "3")
      return "TARIFA FIJA 0122";
    else if (_id == "4")
      return "TARIFA FIJA VAS 0122";
    else if (_id == "5")
      return "TRANSPARENTE";
    else if (_id == "6")
      return "TRANSPARENTE VAS";
    else if (_id == "7")
      return "TARIFA ESTABLE";
    else if (_id == "8")
      return "TARIFA REGULAR";              
    else if (_id == "9")
      return "INDEXADA 4";              
    else if (_id == "10")
      return "INDEXADA 5";              
    else if (_id == "11")
      return "INDEXADA 6";              
    else if (_id == "12")
      return "INDEXADA 10";              
    else if (_id == "13")
      return "INDEXADA 15";              
    else if (_id == "14")
      return "INDEXADA 20";   
    else if (_id == "15")
      return "INDEXADA 25";   

   }
 //JTP
  enviarLogalty(_id){
  var contratoObtenido = this.contratos.find(a=>a.id_contrato==_id);

 

        var requestOptions = {
          method: 'Post',
          headers: {
                        'Accept': "application/json, text/plain, */*",
                        'Content-Type': "application/json;charset=utf-8"
                    },
          redirect: 'follow',
            body:JSON.stringify(contratoObtenido
            )
        };
        fetch("https://apiescandinava.site/html/emas/logalty", requestOptions as any)
.then((res) => alert("Documento enviado para su firma"));
      
    
  }
  enviarLogaltySinAlert(_id){
    var contratoObtenido = this.contratos.find(a=>a.id_contrato==_id);
  
   
  
          var requestOptions = {
            method: 'Post',
            headers: {
                          'Accept': "application/json, text/plain, */*",
                          'Content-Type': "application/json;charset=utf-8"
                      },
            redirect: 'follow',
              body:JSON.stringify(contratoObtenido
              )
          };
          fetch("https://apiescandinava.site/html/emas/logalty", requestOptions as any)
  .then((res) => {});
        
      
    }
    anularSige = async ()=>{
      var ary = [];
      for(var counter =0;counter<this.angularGrid.slickGrid.getSelectedRows().length;counter++){
        var item = this.angularGrid.slickGrid.getSelectedRows()[counter];
      var row = this.angularGrid.slickGrid.getDataItem(item);
        ary.push(row.id);
      }
      if (ary.length==0)
        return;
      var requestOptions = {
        method: 'Post',
        headers: {
                      'Accept': "application/json, text/plain, */*",
                      'Content-Type': "application/json;charset=utf-8"
                  },
        redirect: 'follow',
          body:JSON.stringify(ary
          )
      };
      fetch("https://apiescandinava.site/html/emas/anularsige", requestOptions as any)
.then((res) => {});
    
      this.inicializaListadoContratos();
    }
exportarSige = async () =>{
  var data = [];
  var contratos = this.contratos.filter(a=>a.sige=='no');
  var ary = [];
for(var counter=0;counter<contratos.length;counter++){
  var contratoObtenido = contratos[counter];
  ary.push(contratoObtenido.id_contrato)
  var _direccionIgualASuministro = "";
            var _vincularDireccion="Titular";
            if (contratoObtenido.especificar_direccion_facturacion == "N")
            {
              _direccionIgualASuministro = "Si";
              _vincularDireccion = "Suministro";

              contratoObtenido.codigo_postal_facturacion = "";
              contratoObtenido.ciudad_facturacion = "";
              contratoObtenido.direccion_facturacion = "";
              contratoObtenido.numero_facturacion = "";
              contratoObtenido.aclarador_facturacion = "";
              contratoObtenido.codigo_postal_facturacion = "";
              
            }              

            var _perfilFacturacionContratoTarifa = "ML Indexado Pass Pool";
            if (contratoObtenido.tarifa == "2.0TD")
              _perfilFacturacionContratoTarifa = "Indexado Sin Máximetro";

            var _tipoSolicitud = "MZ";
            var _codigoTipoCambioTitular = "";
              if ((_tipoSolicitud == "MZ") || (_tipoSolicitud == "JZ"))
                _codigoTipoCambioTitular = "S";

            var _codigoREEDistribuidora="";                        
            if(contratoObtenido.CUPS.substring(2,6) == "0031")
            {
                if(contratoObtenido.CUPS.substring(2,8) == "003140")
                  _codigoREEDistribuidora = "0024";
                else if(contratoObtenido.CUPS.substring(2,8) == "003110")
                  _codigoREEDistribuidora = "0023";
                else if(contratoObtenido.CUPS.substring(2,8) == "003130")
                  _codigoREEDistribuidora = "0029";
                else if(contratoObtenido.CUPS.substring(2,8) == "003160")
                  _codigoREEDistribuidora = "0363";
                else if(contratoObtenido.CUPS.substring(2,8) == "003144")
                  _codigoREEDistribuidora = "0396";
                else if(contratoObtenido.CUPS.substring(2,8) == "003150")
                  _codigoREEDistribuidora = "0288";
            }
            else 
              _codigoREEDistribuidora = contratoObtenido.CUPS.substring(2,6);

            var _calendario = "FACTURACION";
            var _codigoProgramacion = "CEEEC01";
            if (contratoObtenido.codigo_postal.toString().trimLeft().trimRight().substring(0,2) == "07")
            {
                _calendario = "BALEARES";
                _codigoProgramacion = "CEEESB";
            }              
            else if ((contratoObtenido.codigo_postal.toString().trimLeft().trimRight().substring(0,2) == "35") || (contratoObtenido.codigo_postal.toString().trimLeft().trimRight().substring(0,2) == "38"))
            {
                _calendario = "CANARIAS";
                _codigoProgramacion = "CEEEGC";
            }
              

            var _tipoSolicitud="";
            if (contratoObtenido.cambios.toString().trimLeft().trimRight().toLowerCase() == "titular")
              _tipoSolicitud = "MZ";
            else if (contratoObtenido.cambios.toString().trimLeft().trimRight().toLowerCase() == "técnicos")
              _tipoSolicitud = "KZ";
            else if (contratoObtenido.cambios.toString().trimLeft().trimRight().toLowerCase() == "no")
              _tipoSolicitud = "CZ";
            else if (contratoObtenido.cambios.toString().trimLeft().trimRight().toLowerCase() == "alta nueva")
              _tipoSolicitud = "AZ";
            else if (contratoObtenido.cambios.toString().trimLeft().trimRight().toLowerCase() == "titular+técnicos")
              _tipoSolicitud = "JZ";

              var _fechaContrato="";
              try {
                _fechaContrato = moment(contratoObtenido.fecha_formalizacion_contrato).add(1,"hour").format('DD/MM/YYYY'); 
              } catch (error) {
                
              }
       


              var item = {
                CodigoCUPS: contratoObtenido.CUPS.replace("0F","").replace("1P","").replace("0P","").replace(/\u200B/g,''),
                CodigoREEDistribuidora: _codigoREEDistribuidora.replace(/\u200B/g,''),
                CallePuntoSuministro: contratoObtenido.direccion.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                Numero:contratoObtenido.numero.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                Aclarador:contratoObtenido.aclarador_titular.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                CodPostal:contratoObtenido.codigo_postal.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                CiudadSuministro:contratoObtenido.ciudad.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                CodigoProvinciaSuministro:contratoObtenido.codigo_postal.toString().trimLeft().trimRight().substring(0,2).replace(/\u200B/g,''),
                ComercializadoraREE:"",
                TextoTarifaAcceso:contratoObtenido.tarifa.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                PerfilFacturacionPeaje:"",
                PotenciaP1:contratoObtenido.potencia_suministro.toString().trimLeft().trimRight().replace(".",",").replace(/\u200B/g,''),
                PotenciaP2:contratoObtenido.potencia_suministro_p2.toString().trimLeft().trimRight().replace(".",",").replace(/\u200B/g,''),
                PotenciaP3:contratoObtenido.potencia_suministro_p3.toString().trimLeft().trimRight().replace(".",",").replace(/\u200B/g,''),
                PotenciaP4:contratoObtenido.potencia_suministro_p4.toString().trimLeft().trimRight().replace(".",",").replace(/\u200B/g,''),
                PotenciaP5:contratoObtenido.potencia_suministro_p5.toString().trimLeft().trimRight().replace(".",",").replace(/\u200B/g,''),
                PotenciaP6:contratoObtenido.potencia_suministro_p6.toString().trimLeft().trimRight().replace(".",",").replace(/\u200B/g,''),
                Identificador:contratoObtenido.numero_documento.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                NombreTitular:contratoObtenido.nombre_titular.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                Apellido1Titular:contratoObtenido.apellido1_titular.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                Apellido2Titular:contratoObtenido.apellido2_titular.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                RazonSocialTitular:contratoObtenido.nombre_empresa.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                DireccionIgualASuministro:_direccionIgualASuministro.replace(/\u200B/g,''),
                CPTitular:contratoObtenido.codigo_postal_facturacion.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                CiudadTitular:contratoObtenido.ciudad_facturacion.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                CalleTitular:contratoObtenido.direccion_facturacion.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                NumeroTitular:contratoObtenido.numero_facturacion.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                AclaradorTitular:contratoObtenido.aclarador_facturacion.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                CodigoProvinciaTitular:contratoObtenido.codigo_postal_facturacion.toString().trimLeft().trimRight().substring(0,2).replace(/\u200B/g,''),
                TfnoTitular:contratoObtenido.numero_telefono_titular.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                EmailTitular:contratoObtenido.correo_titular.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                NombreEnvio:contratoObtenido.nombre_titular.toString().trimLeft().trimRight().replace(/\u200B/g,'') + " " + contratoObtenido.apellido1_titular.toString().trimLeft().trimRight().replace(/\u200B/g,'') + " " + contratoObtenido.apellido2_titular.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                VincularDireccion:_vincularDireccion.replace(/\u200B/g,''),
                CPEnvio:"",
                CiudadEnvio:"",
                CalleEnvio:"",
                NumeroEnvio:"",
                AclaradorEnvio:"",
                CodigoProvinciaEnvio:"",
                NombrePagador:contratoObtenido.nombre_titular.toString().trimLeft().trimRight().replace(/\u200B/g,'') + " " + contratoObtenido.apellido1_titular.toString().trimLeft().trimRight().replace(/\u200B/g,'') + " " + contratoObtenido.apellido2_titular.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                IdentificadorPagador:contratoObtenido.numero_documento.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                TextoTipoCobro:"Recibo Domiciliado a 7 días",
                IBAN:contratoObtenido.IBAN_titular.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                Tarifa:contratoObtenido.tarifa.toString().trimLeft().trimRight().replace(/\u200B/g,'') + " ML",
                TarifaGrupo:this.obtenerGruposTarifa(contratoObtenido.tarifa_grupo.toString().trimLeft().trimRight()).replace(/\u200B/g,''),
                PerfilFacturacionContratoTarifa:_perfilFacturacionContratoTarifa.replace(/\u200B/g,''),
                FechaDesde:"",//VACIO SIEMPRE
                FechaHasta:"",//VACIO SIEMPRE
                ReferenciaExterna:contratoObtenido.numeracion.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                Calendario:_calendario,//En península "FACTURACION", en Canarias "CANARIAS", en Baleares "BALEARES"
                UnidadProgramacion:_codigoProgramacion.replace(/\u200B/g,''),//FORMULA
                TextoPeriodo:"MENSUAL",//siempre
                TextoTipoImpresion:contratoObtenido.texto_tipo_impresion.toString().trimLeft().trimRight().replace(/\u200B/g,''),
                NombreAgente:contratoObtenido.codigo_agente.toString().trimLeft().trimRight().toUpperCase().replace(/\u200B/g,''),
                CodigoVendedor:"",//VACÍO SIEMPRE
                RefCatastral:"",//VACIO SIEMPRE
                RefExterna:"",//VACIO SIEMPRE
                //CodigoCNAE:"9810",//SIEMPRE
                CodigoCNAE:contratoObtenido.codigo_cnae,
                TipoSolicitud:_tipoSolicitud,//DUBTE!!!!!!!!!!
                SolicitudTipoFechaEfecto:"A",//siempre
                Producto:"",//VACIO SIEMPRE
                FechaContrato:_fechaContrato,//DUBTE!!!!!!!
                TextoModeloContrato:"",//VACÍO SIEMPRE
                FechaCore:"",//VACÍO SIEMPRE
                FechaB2B:"",//VACÍO SIEMPRE
                ModeloFactura:"Factura Escandinava",//SIEMPRE
                TipoImpuesto:"",//VACÍO SIEMPRE
                SolicitudMotivoCodificacion:"",//VACÍO SIEMPRE
                Colectivo:"",//VACÍO SIEMPRE??
                CodigoMunicipioSuministro:"",//VACÍO SIEMPRE
                CalendarioCartera:"todos",//SIEMPRE
                TextoMarca:"Escandinava",//SIEMPRE
                NombreRepresentante:this.titleCase(contratoObtenido.agente.toString().trimLeft().trimRight()).replace(/\u200B/g,''),
                CodigoTipoCambioTitular:_codigoTipoCambioTitular,
                NombreAdministrador:"",//VACÍO SIEMPRE
                Segmento:"Escandinava",//SIEMPRE
                DNIRepresentante:"",//VACÍO SIEMPRE
                FechaVencimiento:"",//VACÍO SIEMPRE
                ClavePrecioID:"",//VACÍO SIEMPRE
                CanalCodAge:"T",//SIEMPRE T
                CodigoPromocion:contratoObtenido.codigo_promocional.toString().trimLeft().trimRight().replace(/\u200B/g,'')





                };
data.push(item);
                
          
}
var today = new Date();
                var dd = String(today. getDate()). padStart(2, '0');
                var mm = String(today. getMonth() + 1). padStart(2, '0'); //January is 0!
                var yyyy = today. getFullYear();

                var nombre = "";

                    nombre =  dd + mm + yyyy;   

      
              this.excelService.exportAsExcelFile(data, nombre);
              //todo
              var requestOptions = {
                method: 'Post',
                headers: {
                              'Accept': "application/json, text/plain, */*",
                              'Content-Type': "application/json;charset=utf-8"
                          },
                redirect: 'follow',
                  body:JSON.stringify(ary
                  )
              };
              fetch("https://apiescandinava.site/html/emas/sige", requestOptions as any)
      .then((res) => {});
            
              this.inicializaListadoContratos();
}
   estadoLogalty = async (_id) =>{
    var contratoObtenido = this.contratos.find(a=>a.id_contrato==_id);
   
    var requestOptions = {
      method: 'Post',
      headers: {
                    'Accept': "application/json, text/plain, */*",
                    'Content-Type': "application/json;charset=utf-8"
                },
      redirect: 'follow',
        body:JSON.stringify(contratoObtenido
        )
    };
    var response = await fetch("https://apiescandinava.site/html/emas/status", requestOptions as any);
    var json = await response.text();
    alert(json);
//       }
    // )
  }
    visualizarPDFContrato(_id)
    {
        this.abrirDialogContrato(true,_id,true);
    }

    editarContrato(_id)
    {
        this.abrirDialogContrato(true,_id);
    }

    eliminarContrato(_id)
    {
        this.spinner.show();
        this.http.eliminarContrato(_id).then(
          () => {
            this.spinner.hide();
            this.notifyService.showMessage("Contrato eliminado correctamente", "Success",false);
            this.inicializaListadoContratos();
          }
        )
    }

    inicializaListadoContratos()
    {

        this.http.obtenerContratos().then(
           async (contratosObtenidos:Array<any>) => {
            
            //alert(JSON.stringify(contratosObtenidos));
            //call my api to get all status from SIGE and from Logalty
            //this.status =status;
            var requestOptions = {
              method: 'Get',
              headers: {
                            'Accept': "application/json, text/plain, */*",
                            'Content-Type': "application/json;charset=utf-8"
                        },
            };
            var response = await fetch("https://apiescandinava.site/html/emas/aggregated", requestOptions as any);
            this.aggregated = await response.json();
            this.contratos = contratosObtenidos;
            console.log('Numero de contratos:' + this.contratos.length)

           
            await this.filtrar(); 
            this.spinner.hide();
            this.pageReady = true;

          }, 
          (err) => {
            console.log("ERR");
            this.spinner.hide();
            this.pageReady = true;
            this.filtrar();
          }
        )


    }
  enviarLogaltyGrupo = () =>{
    console.log(this.angularGrid.slickGrid.getSelectedRows().length);
    for(var counter =0;counter<this.angularGrid.slickGrid.getSelectedRows().length;counter++){
      var item = this.angularGrid.slickGrid.getSelectedRows()[counter];
      var row = this.angularGrid.slickGrid.getDataItem(item);
      this.enviarLogaltySinAlert(row.id );
    }
    alert('Documentos enviados');
    this.inicializaListadoContratos();
  }
    angularGridReady(angularGrid: AngularGridInstance) {
      this.angularGrid = angularGrid;
  
      
      
  
          this.angularGrid.slickGrid.onDblClick.subscribe((e,args) => {
  
                this.abrirDialogContrato(true,args.grid.getDataItem(args.row).id);
        });
   
  
    };


    filtrar() {
      /*
      alert("filtrar");
      this.filtrado = this.inversiones.filter((art) =>
        art.IMPORTE.toString()
          .toLowerCase()
          .includes(valor.toString().toLowerCase())
      );*/
  
      this.dataset1 = [];
      
      this.rowData = this.contratos;
      
      this.source = this.filtrado;
  
      //slickgrid
      // this.slick.beginUpdate();
      
      var datosFormatados = [];
      return new Promise((resolve) => {
        
        for (var i = 0; i <= this.contratos.length-1; i++) {
  
          //var fechaFormatada = this.datePipe.transform(this.rowData[i].FECHA, 'yyyy-MM-dd');    
          //nombre_usuario_asignado
          var sige = this.aggregated.sige.find(a=>a==this.rowData[i].id_contrato);
          var logalty = this.aggregated.logalty.find(a=>a.id==this.rowData[i].id_contrato);
          var logaltyStatus='';
          if(logalty==undefined || logalty==null ){
            logaltyStatus='No enviado';
          }else{
            if(logalty.status=="0"){
              logaltyStatus='Documento enviado, Pendiente de Firma';
            }
            else if(logalty.status=="7"){
              logaltyStatus='Documento Firmado';
            }
            else{
              logaltyStatus='Documento Cancelado';
            }
          }
          datosFormatados[i] = {
            id: this.rowData[i].id_contrato,
            CUPS: this.rowData[i].CUPS,
            direccion: this.rowData[i].direccion + ", " + this.rowData[i].numero + ", " + this.rowData[i].aclarador_titular + ", " + this.rowData[i].codigo_postal + ", " + this.rowData[i].ciudad,            
            nombre: this.rowData[i].nombre_titular + " " + this.rowData[i].apellido1_titular + " " + this.rowData[i].apellido2_titular + " " + this.rowData[i].nombre_empresa,
            email: this.rowData[i].correo_titular,
            telefono: this.rowData[i].numero_telefono_titular,
            logalty:logaltyStatus,//find in status with id_contrato
            sige: (sige==undefined || sige==null ) ? 'No' : 'Si'
          };
          this.contratos[i].sige='no';
          this.contratos[i].logalty='';
  
  
          if (i == (this.rowData.length-1)) 
          {
  
            resolve(this);
          }
            
        }
      }).then(() => {
        this.dataset1 = datosFormatados;
        
      });
    }

    async ngOnInit() 
    {

    
        //this.spinner.show();
        
        /*
        const actionFormatter: Formatter = (row, cell, value, columnDef, dataContext) => {          
              return `<div class="fake-hyperlink">Acciones <i class="fa fa-caret-down"></i></div>`;          
        };
                      
        this.columnDefinitions1 = [  
          {
            id: 'porcentajeDatosCompletados', nameKey: 'porcentajeDatosCompletados', field: 'porcentajeDatosCompletados', name: "% Datos completados",
            excludeFromExport: true,
            sortable: true, filterable: true,
            //maxWidth: 80,
            filter: { model: Filters.slider, operator: '>=' },
            formatter: Formatters.progressBar, type: FieldType.number,
          },
          { 
              id: 'nombre',
              name: 'Nombre',
              field: 'nombre',
              filterable:true, 
              sortable: true,  
              columnGroup: 'Datos personales',
              width: 130,
              type: FieldType.string,
              filter: {        
                  enableTrimWhiteSpace: true
              }
          },       
          { 
            id: 'email',
            name: 'Email',
            field: 'email',
            sortable: true,
            filterable:true, 
            columnGroup: 'Datos personales',
            width: 170,
            type: FieldType.string,
            filter: {        
                enableTrimWhiteSpace: true
            }
          },        
            { 
              id: 'telefono',
              name: 'Teléfono',
              field: 'telefono',
              width: 100,
              columnGroup: 'Datos personales',
              sortable: true,
              filterable:true,
              type: FieldType.string,
              filter: {        
                enableTrimWhiteSpace: true
              } 
            },     
            { 
              id: 'horario_llamada',
              name: 'Horario de llamada',
              field: 'horario_llamada',
              maxWidth: 100,
              columnGroup: 'Datos personales',
              sortable: true,
              filterable:true,
              type: FieldType.string,
              filter: {        
                enableTrimWhiteSpace: true
              } 
            },        
            { 
              id: 'poblacion',
              name: 'Población',
              field: 'poblacion',
              filterable:true,
              width: 150,
              columnGroup: 'Datos personales',
              sortable: true,
              type: FieldType.string,
                filter: {        
                  enableTrimWhiteSpace: true
                } 
            },           
            { 
              id: 'cups',
              name: 'CUPS',
              field: 'cups',
              columnGroup: 'Datos técnicos',
              filterable:true,
              sortable: true,
              type: FieldType.string,
                filter: {        
                  enableTrimWhiteSpace: true
                } 
            },                              
            { 
              id: 'procedencia',
              name: 'Procedencia',
              field: 'procedencia',
              columnGroup: 'Datos técnicos',
              filterable:true,
              sortable: true,
              width:150,
              type: FieldType.string,
                filter: {        
                  enableTrimWhiteSpace: true
                } 
            }, 
            { 
              id: 'ahorro',
              name: '% Ahorro',
              field: 'ahorro',
              columnGroup: 'Datos técnicos',
              filterable:true,
              sortable: true,
              maxWidth: 70,
              type: FieldType.string,
                filter: {        
                  enableTrimWhiteSpace: true
                } 
            }, 
            { 
              id: 'tarifa_a_contratar',
              name: 'Tarifa a contratar',
              field: 'tarifa_a_contratar',
              columnGroup: 'EdE',
              filterable:true,
              sortable: true,              
              filter: {
                collection: [
                  { value: '', label: ''},
                  { value: 'transparente', label: 'TRANSPARENTE' },
                  { value: 'promocion_2.0td', label: 'PROMOCION 2.0TD' },
                  { value: 'fija', label: 'FIJA' },
                  { value: '3.0TD', label: '3.0TD' },
                  { value: '6.1TD', label: '6.1TD' }                  
                ],
                model: Filters.singleSelect,
                filterOptions: {
                  autoDropWidth: true
                }
              }
            },  
            { 
              id: 'lead_status',
              name: 'Lead status',
              field: 'lead_status',
              columnGroup: 'EdE',
              filterable:true,
              sortable: true,              
              filter: {
                collection: [
                  { value: '', label: ''},
                  { value: 'New', label: 'New' },
                  { value: 'Open', label: 'Open' },
                  { value: 'In_progress', label: 'In progress' },
                  { value: 'Open_deal', label: 'Open deal' },
                  { value: 'Unqualified', label: 'Unqualified' },
                  { value: 'Attempted_to_contact', label: 'Attempted to contact' },
                  { value: 'Connected', label: 'Connected' },
                  { value: 'Bad_timing', label: 'Bad timing' },
                ],
                model: Filters.singleSelect,
                filterOptions: {
                  autoDropWidth: true
                }
              }
            },  
            { 
              id: 'lifecycle_stage',
              name: 'Lifecycle stage',
              field: 'lifecycle_stage',
              columnGroup: 'EdE',
              filterable:true,
              width:60,
              sortable: true,              
              filter: {
                collection: [
                  { value: '', label: ''},
                  { value: 'Subscriber', label: 'Subscriber' },
                  { value: 'Lead', label: 'Lead' },
                  { value: 'Marketing_qualified_lead', label: 'Marketing qualified lead' },
                  { value: 'Sales_qualified_lead', label: 'Sales qualified lead' },
                  { value: 'Opportunity', label: 'Opportunity' },
                  { value: 'Customer', label: 'Customer' },
                  { value: 'Evangelist', label: 'Evangelist' },
                  { value: 'Other', label: 'Other' },
                ],
                model: Filters.singleSelect,
                filterOptions: {
                  autoDropWidth: true
                }
              }
            },  
            { 
              id: 'owner',
              name: 'Owner',
              field: 'owner',
              columnGroup: 'EdE',
              filterable:true,
              sortable: true,
              width:100,
              type: FieldType.string,
                filter: {        
                  enableTrimWhiteSpace: true
                } 
            },              
            
            {
                id: 'acciones',
                name: 'Acciones',
                field: 'acciones',  
                columnGroup: 'Acciones',            
                maxWidth: 120,
                excludeFromExport: true,
                formatter: actionFormatter,
                cellMenu: {
                  hideCloseButton: false,
                  width: 200,             
                  menuUsabilityOverride: (args) => {
                    return true;
                  },                
                  commandItems: [                
                    { command: 'editar', title: 'Editar datos', positionOrder: 62 },
                    { command: 'generar', title: 'Generar contrato', positionOrder: 63, disabled: true},                                                                                 
                    { command: 'enviar', title: 'Enviar contrato lleidanet', positionOrder: 64, disabled: true},                                                                                 
                  ],
                
                }
              },
            
            
        ];
        

      this.gridOptions1 = {
      columnPicker: {
        hideForceFitButton: true,
        hideSyncResizeButton: true,
        onColumnsChanged: (e, args) => {
          console.log('Column selection changed from Column Picker, visible columns: ', args.columns);
        }
      },
      createPreHeaderPanel: true,
      showPreHeaderPanel: true,
      preHeaderPanelHeight: 28,
      //enableAutoResize: true,
      forceFitColumns:true,
      enableGridMenu: true,
      autoHeight: true,    
      // enable the filtering but hide the user filter row since we use our own single filter
      enableFiltering: true,
      showHeaderRow: true, // hide the filter row (header row)

      alwaysShowVerticalScroll: false,
      enableColumnPicker: true,
      enableRowSelection: true,
      enablePagination: true,
        pagination: {
          pageSizes: [15,30,50,100],
          pageSize: 15
        },
        enableCellMenu: true,
     
      enableCellNavigation: true,
      enableSorting: true,
      excelExportOptions: {
          exportWithFormatter: true,
          customColumnWidth: 15,
          columnHeaderStyle: { font: { bold: true, italic: true } }
      },

      enableContextMenu: true,


      };
      */
    }

    abrirDialogContrato(_edicion=false,_id_contrato,_exportarPDF=false)
    {
      var _contrato = new contratos();
      //var sed = new INV_Sedes(0,0,"","","","","",0,"");

      //console.log("SEDE REBUDA " + JSON.stringify(sede));
    
      
      var _tituloModal = "";
      return new Promise((resolve) => {
          if(_edicion)
          {
            this.tituloModal = "Editar contrato";
            this.http.obtenerContratoById(_id_contrato).then(
              (contratoObtenido) => {
                this.edicion = true;
                resolve(contratoObtenido);
              }
            )
          }
          else
          {
              this.tituloModal = "Nuevo contrato";
              this.edicion = false;
              resolve(new contratos());
          }
            
      }).then(
        (_contrato) => {

          
          const dialogRef = this.dialog.open(INV_CreacionEdicionContrato, {
            //width: '90%',
            maxWidth: '90vw',
            maxHeight: '80vh',
            height: '80%',
            width: '90%',
              data: {tituloModal: this.tituloModal,edicion: this.edicion,contrato:_contrato,exportarPDF:_exportarPDF}
          });
        
        
           dialogRef.afterClosed().subscribe(result => {
        
              if (result)
              {
                                            
                    var contrato = result.contrato;
                    
                //    alert("POTENCIA " + result.contrato.potencia_suministro);
                    //alert(JSON.stringify(result));
                    this.spinner.show();

                    result.contrato.fecha_formalizacion_contrato = moment(result.contrato.fecha_formalizacion_contrato).add(1,"hour").format('YYYY-MM-DD');                    
                  
                
                    if (result.contrato.especificar_direccion_facturacion == "N")
                    {
                          result.contrato.direccion_facturacion = result.contrato.direccion;
                          result.contrato.aclarador_facturacion = result.contrato.aclarador_titular;
                          result.contrato.numero_facturacion = result.contrato.numero;
                          result.contrato.codigo_postal_facturacion = result.contrato.codigo_postal;
                          result.contrato.ciudad_facturacion = result.contrato.ciudad;                        
                          result.contrato.provincia_facturacion = result.contrato.provincia;     
                    }
                    
                    if (result.edicion) //UPDATE
                    {
                      
                      this.http.actualizar_contrato(contrato).then(
                        (resultat) => {
                          //console.log("Sede creada correctamente" + JSON.stringify(resultat));
                          this.notifyService.showMessage("Contrato actualizado correctamente", "Success",false);
                          this.inicializaListadoContratos();
                        });
                    }
                    else //CREATE
                    {
                      //alert(JSON.stringify(contrato));
                      this.http.crearContrato(contrato).then(
                        (resultat) => {
                          //console.log("Sede creada correctamente" + JSON.stringify(resultat));
                          this.notifyService.showMessage("Contrato creado correctamente", "Success",false);
                          this.inicializaListadoContratos();
                        });
            
                    }
                
            }
        
          });

        }
      )                      

    }

    ngAfterViewInit()
    {
      
    }

   




    float2int (value) {
      return value | 0;
  }

   fileUpload(formElement) {
    const formData = new FormData(formElement);
    try {
      const response = fetch("http://escandinava.emas.es/api/contratos/file-upload", {
        method: "POST",
        body: formData,
        
      });
  
    } catch (e) {
        console.log(e);
      alert("some error occured while uploading file");
    }
  }


  }
